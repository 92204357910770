import styled from 'styled-components';

export const WrapperAvatar = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  border: 2px solid ${(props) => props.theme.palette.common.subText};
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid ${(props) => props.theme.palette.white};
  }
`;
