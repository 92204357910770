import { DownloadIcon } from '@/icons';
import { IExportFeedbackProps } from './ExportFeedback.types';
import {
  StyledAnchor,
  FeedbackContentWrapper,
  StyledLinearProgress,
  StyledSnackbar,
  ProgressWrapper,
} from './ExportFeedback.styles';
import { useTranslation } from 'react-i18next';

const ExportFeedback = ({
  loading,
  href,
  open,
  onClose,
  disabled,
}: IExportFeedbackProps) => {
  const { t } = useTranslation();

  const feedbackMessage = loading ? (
    <ProgressWrapper>
      <span>{t('components.export.downloading')}</span>
      <StyledLinearProgress color="primary" />
    </ProgressWrapper>
  ) : href ? (
    <FeedbackContentWrapper>
      <DownloadIcon />
      <StyledAnchor
        href={href || '#'}
        download
        target={'_blank'}
        rel="noreferrer noopener"
      >
        {t('components.export.downloaded')}
      </StyledAnchor>
    </FeedbackContentWrapper>
  ) : null;

  return (
    <StyledSnackbar
      open={open}
      onClick={disabled ? undefined : onClose}
      message={feedbackMessage}
    />
  );
};

export default ExportFeedback;
