import { ButtonBaseWrapper } from './BaseButton.styles';
import { IBaseButton } from './BaseButton.types';
import { FC } from 'react';

const Button: FC<IBaseButton> = ({
  className,
  withGradient = false,
  error = false,
  children,
  fullWidth = false,
  ...props
}) => {
  return (
    <ButtonBaseWrapper
      className={className}
      $withGradient={withGradient}
      $error={error}
      $fullWidth={fullWidth}
      {...props}
    >
      {children}
    </ButtonBaseWrapper>
  );
};

export default Button;
