import { FC } from 'react';
import { WrapperLogo } from './Logo.styles';
import { ILogo } from './Logo.types';
const Logo: FC<ILogo> = ({ size, white = false, className }) => {
  return (
    <WrapperLogo className={className}>
      <img
        src={
          white
            ? '/images/insparya_white_logo.svg'
            : '/images/insparya_logo.svg'
        }
        alt="insparya logo"
        style={size ? { width: size } : {}}
      />
    </WrapperLogo>
  );
};

export default Logo;
