import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const Sales = lazy(() => import('@/pages/Sales'));
const SalesEdit = lazy(() => import('@/pages/SalesEdit'));

const SALES_ROUTES = (
  <Route
    path="/dashboard/vendas/"
    element={<Outlet />}
    key="/dashboard/vendas/"
  >
    <Route path="/dashboard/vendas/" element={<Sales />} />
    <Route path="/dashboard/vendas/:id/" element={<SalesEdit />} />
  </Route>
);

export default SALES_ROUTES;
