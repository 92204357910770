import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { animation } from '@/styles/mixins';

export const StyledDivider = styled(Divider)`
  && {
    border-color: #60626e;
    margin: 10px 0;
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  && {
    display: none;
    transform: rotate(270deg);
    fill: ${(props) => props.theme.palette.white};
  }
`;

export const MenuListWrapper = styled.div<{
  $isSubmenu?: boolean;
  $isLastSubmenu?: boolean;
}>`
  > ul {
    > li {
      .MuiListItemButton-root {
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        &:hover {
          background-color: transparent;
        }
      }

      .MuiListItemIcon-root {
        min-width: 40px;
        svg {
          width: 20px;
          fill: #60626e;
          opacity: 0.7;
          ${animation({ time: '.3s' })}
        }
      }

      .MuiTypography-root {
        font-family: ${(props) => props.theme.fontFamilies.montserrat};
        color: #a4a6b3;
        font-size: 15px;
        ${animation({ time: '.3s' })}
      }

      a {
        position: relative;
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        ${animation({ time: '.3s' })}
        &:after {
          position: absolute;
          content: '';
          right: 0;
          top: 0;
          height: 100%;
          width: 3px;
          background-color: ${(props) => props.theme.palette.common.main};
          z-index: 1;
          opacity: 0;
          ${animation({ time: '.3s' })}
        }

        &.active,
        &:hover {
          background-color: #3e4049;
          &:after {
            opacity: 1;
          }

          .MuiTypography-root {
            color: #dde2ff;
            opacity: 1;
          }
          svg {
            fill: #dde2ff !important;
            opacity: 1 !important;
          }
        }
      }

      ul {
        background-color: ${(props) => props.theme.palette.common.menu};
      }
    }
  }
`;

export const StyledTitle = styled.span`
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  ${animation({ time: '.3s' })}
  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: ${(props) => props.theme.palette.common.main};
    z-index: 1;
    opacity: 0;
    ${animation({ time: '.3s' })}
  }
  &.active,
  &:hover {
    background-color: #3e4049;

    &:after {
      opacity: 1;
    }

    .MuiListItemIcon-root,
    .MuiTypography-root {
      color: #dde2ff;
      opacity: 1;
    }
    svg {
      fill: #dde2ff !important;
      opacity: 1 !important;
    }
  }
`;
