import { FC, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import MenuList from '@/components/MenuList';
import Dialog from '../Dialog';
import { IDrawer } from './Menu.types';

import useAuth from '@/hooks/Auth';
import useAxioPrivate from '@/hooks/AxiosPrivate';
import emitAlert from '@/utils/Toastify';
import { Link } from 'react-router-dom';
import inMemoryJwt from '@/utils/InMemoryJwt';
import userSession from '@/utils/UserSession';
import refreshTokenPromiseManager from '@/utils/RefreshTokenPromise/';
import usePermissionGate from '@/hooks/PermissionGate/PermissionGate.hook';
import { IMenuListItem } from '@/types';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SocialDistanceOutlined from '@mui/icons-material/SocialDistanceOutlined';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';
import PolicyOutlined from '@mui/icons-material/PolicyOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import { useTranslation } from 'react-i18next';
import useLanguage from '@/hooks/Language';

import {
  Menu,
  StyledDivider,
  StyledLanguageDropdown,
  StyledLogo,
  Wrapper,
} from './Menu.styles';

import {
  PartnersIcon,
  ConsultasIcon,
  LeadsIcon,
  GestaoAppIcon,
  ComissoesIcon,
  GestaoParceirosIcon,
  CampanhasIcon,
  ChatIcon,
  SettingsIcon,
  LogoutIcon,
  NotificationsIcon,
  NewsIcon,
  ContratoIcon,
  ServicosIcon,
  GaleriaIcon,
  ContentSettingsIcon,
  PeopleListIcon,
  FaceIcon,
  VoucherIcon,
  AssignmentPartnershipIcon,
} from '@/icons';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

const CustomMenu: FC<IDrawer> = ({ className }) => {
  const axiosPrivate = useAxioPrivate();
  const { setUser, user } = useAuth();
  const { t } = useTranslation();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleToggleLogoutDialog = useCallback(() => {
    setOpenLogoutDialog((prev) => !prev);
  }, []);

  const Logout = async () => {
    try {
      const { data } = await axiosPrivate.post('/api/logout');

      if (data) {
        emitAlert({
          message: t('menu.end-session'),
          type: 'warn',
          duration: 2500,
        });
        setUser(null);
        inMemoryJwt.eraseToken();
        userSession.remove();
        refreshTokenPromiseManager.erasePromise();
      }
    } catch (err: any) {
      emitAlert({
        message: err?.response.data?.error?.message || err.toString(),
        type: 'error',
        duration: 2500,
      });
    }
  };

  const MENU_LIST = usePermissionGate<IMenuListItem>([
    {
      text: 'menu.recommenders',
      url: '#',
      icon: <PartnersIcon />,
      submenu: [
        {
          text: 'menu.partners',
          url: '/dashboard/parceiros',
          icon: <PeopleListIcon />,
          requiredPermission: {
            allowedModules: ['partners'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.clients',
          url: '/dashboard/parceiros/clientes',
          icon: <FaceIcon />,
          requiredPermission: {
            allowedModules: ['partners.clients'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.pendings',
          url: '/dashboard/parceiros/pendentes',
          icon: <ContentPasteSearchIcon />,
          requiredPermission: {
            allowedModules: ['partners.pendings'],
            allowedAction: 'readMany',
          },
        },
      ],
    },
    {
      text: 'menu.sales',
      url: '/dashboard/vendas',
      icon: <ConsultasIcon />,
      requiredPermission: {
        operator: 'OR',
        allowedModules: ['sales', 'clinicAppointments', 'itemsSold'],
        allowedAction: 'readMany',
      },
    },
    {
      text: 'menu.leads',
      url: '/dashboard/leads',
      icon: <LeadsIcon />,
      requiredPermission: {
        allowedModules: ['leads'],
        allowedAction: 'readMany',
      },
    },
    {
      text: 'menu.manager-app',
      url: '#',
      icon: <GestaoAppIcon />,
      submenu: [
        {
          text: 'menu.notifications',
          url: '/dashboard/notificacoes',
          icon: <NotificationsIcon />,
          requiredPermission: {
            allowedModules: ['notifications'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.news',
          url: '/dashboard/noticias',
          icon: <NewsIcon />,
          requiredPermission: {
            allowedModules: ['news'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.contract',
          url: '/dashboard/contrato',
          icon: <ContratoIcon />,
          requiredPermission: {
            allowedModules: ['contracts'],
            allowedAction: 'readOne',
          },
        },
        {
          text: 'menu.services',
          url: '/dashboard/servicos',
          icon: <ServicosIcon />,
          requiredPermission: {
            allowedModules: ['services'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.gallery',
          url: '/dashboard/galeria',
          icon: <GaleriaIcon />,
          requiredPermission: {
            allowedModules: ['files'],
            allowedAction: 'readMany',
          },
        },
      ],
    },
    {
      text: 'menu.commissions',
      url: '/dashboard/comissoes',
      icon: <ComissoesIcon />,
      requiredPermission: {
        allowedModules: ['commissions'],
        allowedAction: 'readMany',
        operator: 'OR',
      },
    },
    {
      text: 'menu.manager-partners',
      url: '#',
      icon: <GestaoParceirosIcon />,
      submenu: [
        {
          text: 'menu.activity-dashboard',
          url: '/dashboard/gestao-parceiros',
          icon: <DashboardOutlined />,
          requiredPermission: {
            allowedModules: ['activityDashboards'],
            allowedAction: 'readOne',
          },
        },
        {
          text: 'menu.chat',
          url: '/dashboard/chat',
          icon: <ChatIcon />,
          requiredPermission: {
            allowedModules: ['chats'],
            allowedAction: 'readMany',
          },
        },
      ],
    },
    {
      text: 'menu.campaigns',
      url: '/dashboard/campanhas',
      icon: <CampanhasIcon />,
      requiredPermission: {
        allowedModules: ['campaigns'],
        allowedAction: 'readMany',
      },
    },
    {
      text: 'menu.recommendation',
      url: '/dashboard/criar-recomendacao',
      icon: <SocialDistanceOutlined />,
      requiredPermission: {
        allowedModules: ['partners', 'leads'],
        allowedAction: 'create',
      },
    },
    {
      text: 'menu.assignment-partnership',
      url: '/dashboard/atribuir-parceria',
      icon: <AssignmentPartnershipIcon />,
      requiredPermission: {
        allowedModules: ['assignmentPartnership'],
        allowedAction: 'create',
      },
    },
    {
      text: 'menu.voucher-pack',
      url: 'dashboard/voucher-packs',
      icon: <VoucherIcon />,
      requiredPermission: {
        allowedModules: ['voucherPacks'],
        allowedAction: 'readMany',
      },
      divider: true,
    },
    {
      text: 'menu.definitions',
      url: '#',
      icon: <SettingsIcon />,
      isLastSubmenu: true, // use this to make submenu fixed and not overflow window bottom
      submenu: [
        {
          text: 'menu.logs',
          url: '/dashboard/logs',
          icon: <TextSnippetOutlined />,
          requiredPermission: {
            allowedModules: ['fieldLevelChangesLogs'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.voucher-logs',
          url: '/dashboard/voucher-logs',
          icon: <TextSnippetOutlined />,
          requiredPermission: {
            allowedModules: ['voucherLogs'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.business-units',
          url: '/dashboard/unidades',
          icon: <ApartmentOutlinedIcon />,
          requiredPermission: {
            allowedModules: ['businessUnits'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.attribution-rules',
          url: '/dashboard/regras-atribuicao',
          icon: <ContentSettingsIcon />,
          requiredPermission: {
            allowedModules: ['attributionRules'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.parameters',
          url: '/dashboard/parametros',
          icon: <ContentSettingsIcon />,
          requiredPermission: {
            allowedModules: ['parameters'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.policies',
          url: '/dashboard/politicas',
          icon: <PolicyOutlined />,
          requiredPermission: {
            allowedModules: ['policies'],
            allowedAction: 'readMany',
          },
        },
        {
          text: 'menu.users',
          url: '/dashboard/gestao-utilizadores',
          icon: <PartnersIcon />,
          requiredPermission:
            user?.data?.userType?.name === 'PARTNERS_MANAGER'
              ? {
                  allowedAction: 'none',
                  allowedModules: ['users'],
                }
              : undefined,
        },
      ],
    },
    {
      text: 'menu.exit',
      url: '#',
      icon: <LogoutIcon />,
      onClick: handleToggleLogoutDialog,
    },
  ]);

  const { selectedLng, setSelectedLng } = useLanguage();

  const handleLanguageChange = useCallback(
    (value: string) => {
      setSelectedLng(value);
    },
    [setSelectedLng]
  );

  return (
    <Wrapper className={className}>
      <DrawerHeader>
        <Link to="/dashboard">
          <StyledLogo white />
        </Link>
      </DrawerHeader>
      <StyledLanguageDropdown
        lng={selectedLng}
        onSelect={handleLanguageChange}
      />
      <StyledDivider />
      <Menu>
        <MenuList menu={MENU_LIST}>
          {(submenu, isLastSubmenu) =>
            submenu &&
            submenu.length > 0 && (
              <MenuList
                menu={submenu}
                isSubmenu
                isLastSubmenu={isLastSubmenu}
              />
            )
          }
        </MenuList>
      </Menu>
      <Dialog
        open={openLogoutDialog}
        title={t('menu.exit-panel-control')}
        content={t('menu.exit-confirm')}
        onClose={handleToggleLogoutDialog}
        onConfirm={Logout}
      />
    </Wrapper>
  );
};

export default CustomMenu;
