import { axiosPrivate } from '@/utils/Axios';
import { useEffect } from 'react';
import useRefreshToken from '@/hooks/RefreshToken';
import { AxiosRequestConfig } from 'axios';

import inMemoryJwt from '@/utils/InMemoryJwt/InMemoryJwt';
import { useBusinessUnit } from '../BusinessUnit';
import { useLanguage } from '../Language';

const useAxiosPrivate = () => {
  const refreshToken = useRefreshToken();
  const { selectedBusinessUnit } = useBusinessUnit();
  const { selectedLng } = useLanguage();

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (config?.headers) {
          config.headers['Authorization'] = `Bearer ${inMemoryJwt.getToken()}`;
          if (!config.headers['bu'] && selectedBusinessUnit) {
            config.headers['bu'] = selectedBusinessUnit?.toUpperCase();
          }

          if (selectedLng) {
            config.headers['accept-language'] = selectedLng;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
    };
  }, [selectedBusinessUnit, selectedLng]);

  useEffect(() => {
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequestConfig = error?.config;

        if (
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          !prevRequestConfig?._retry
        ) {
          prevRequestConfig._retry = true;

          await refreshToken();

          return axiosPrivate(prevRequestConfig);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refreshToken]);

  return axiosPrivate;
};

export default useAxiosPrivate;
