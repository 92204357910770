import { IDialCountry } from '@/types';

const DIAL_CODES: IDialCountry[] = [
  { name: 'Ascension Island', countryCode: 'AC', dialCode: '+247' },
  { name: 'Afghanistan', countryCode: 'AF', dialCode: '+93' },
  { name: 'Alland Islands', countryCode: 'AX', dialCode: '+358' },
  { name: 'Albania', countryCode: 'AL', dialCode: '+355' },
  { name: 'Algeria', countryCode: 'DZ', dialCode: '+213' },
  { name: 'American Samoa', countryCode: 'AS', dialCode: '+1-684' },
  { name: 'Andorra', countryCode: 'AD', dialCode: '+376' },
  { name: 'Angola', countryCode: 'AO', dialCode: '+244' },
  { name: 'Anguilla', countryCode: 'AI', dialCode: '+1-264' },
  { name: 'Antarctica', countryCode: 'AQ', dialCode: '+672' },
  { name: 'Antigua and Barbuda', countryCode: 'AG', dialCode: '+1-268' },
  { name: 'Argentina', countryCode: 'AR', dialCode: '+54' },
  { name: 'Armenia', countryCode: 'AM', dialCode: '+374' },
  { name: 'Aruba', countryCode: 'AW', dialCode: '+297' },
  { name: 'Australia', countryCode: 'AU', dialCode: '+61' },
  { name: 'Austria', countryCode: 'AT', dialCode: '+43' },
  { name: 'Azerbaijan', countryCode: 'AZ', dialCode: '+994' },
  { name: 'Bahamas', countryCode: 'BS', dialCode: '+1-242' },
  { name: 'Bahrain', countryCode: 'BH', dialCode: '+973' },
  { name: 'Bangladesh', countryCode: 'BD', dialCode: '+880' },
  { name: 'Barbados', countryCode: 'BB', dialCode: '+1-246' },
  { name: 'Belarus', countryCode: 'BY', dialCode: '+375' },
  { name: 'Belgium', countryCode: 'BE', dialCode: '+32' },
  { name: 'Belize', countryCode: 'BZ', dialCode: '+501' },
  { name: 'Benin', countryCode: 'BJ', dialCode: '+229' },
  { name: 'Bermuda', countryCode: 'BM', dialCode: '+1-441' },
  { name: 'Bhutan', countryCode: 'BT', dialCode: '+975' },
  { name: 'Bolivia', countryCode: 'BO', dialCode: '+591' },
  { name: 'Bonaire', countryCode: 'BQ', dialCode: '+599-7' },
  { name: 'Bosnia and Herzegovina', countryCode: 'BA', dialCode: '+387' },
  { name: 'Botswana', countryCode: 'BW', dialCode: '+267' },
  { name: 'Bouvet Island', countryCode: 'BV', dialCode: '+47' },
  { name: 'Brazil', countryCode: 'BR', dialCode: '+55' },
  {
    name: 'British Indian Ocean Territory',
    countryCode: 'IO',
    dialCode: '+246',
  },
  {
    name: 'British Virgin Islands',
    countryCode: 'VG',
    dialCode: '+1-284',
  },
  { name: 'Brunei Darussalam', countryCode: 'BN', dialCode: '+673' },
  { name: 'Bulgaria', countryCode: 'BG', dialCode: '+359' },
  { name: 'Burkina Faso', countryCode: 'BF', dialCode: '+226' },
  { name: 'Burundi', countryCode: 'BI', dialCode: '+257' },
  { name: 'Cambodia', countryCode: 'KH', dialCode: '+855' },
  { name: 'Cameroon', countryCode: 'CM', dialCode: '+237' },
  { name: 'Canada', countryCode: 'CA', dialCode: '+1' },
  { name: 'Cape Verde', countryCode: 'CV', dialCode: '+238' },
  { name: 'Cayman Islands', countryCode: 'KY', dialCode: '+1-345' },
  {
    name: 'Central African Republic',
    countryCode: 'CF',
    dialCode: '+236',
  },
  { name: 'Chad', countryCode: 'TD', dialCode: '+235' },
  { name: 'Chile', countryCode: 'CL', dialCode: '+56' },
  { name: 'China', countryCode: 'CN', dialCode: '+86' },
  { name: 'Christmas Island', countryCode: 'CX', dialCode: '+61' },
  { name: 'Cocos (Keeling) Islands', countryCode: 'CC', dialCode: '+61' },
  { name: 'Colombia', countryCode: 'CO', dialCode: '+57' },
  { name: 'Comoros', countryCode: 'KM', dialCode: '+269' },
  {
    name: 'Congo, Democratic Republic of the',
    countryCode: 'CG',
    dialCode: '+243',
  },
  {
    name: 'Congo, Republic of the',
    countryCode: 'CD',
    dialCode: '+242',
  },
  { name: 'Cook Islands', countryCode: 'CK', dialCode: '+682' },
  { name: 'Costa Rica', countryCode: 'CR', dialCode: '+506' },
  { name: "Cote d'Ivoire", countryCode: 'CI', dialCode: '+225' },
  { name: 'Croatia', countryCode: 'HR', dialCode: '+385' },
  { name: 'Cuba', countryCode: 'CU', dialCode: '+53' },
  { name: 'Curacao', countryCode: 'CW', dialCode: '+599-9' },
  { name: 'Cyprus', countryCode: 'CY', dialCode: '+357' },
  { name: 'Czech Republic', countryCode: 'CZ', dialCode: '+420' },
  { name: 'Denmark', countryCode: 'DK', dialCode: '+45' },
  { name: 'Djibouti', countryCode: 'DJ', dialCode: '+253' },
  { name: 'Dominica', countryCode: 'DM', dialCode: '+1-767' },
  { name: 'Dominican Republic', countryCode: 'DO', dialCode: '+1-809' },
  { name: 'Ecuador', countryCode: 'EC', dialCode: '+593' },
  { name: 'Egypt', countryCode: 'EG', dialCode: '+20' },
  { name: 'El Salvador', countryCode: 'SV', dialCode: '+503' },
  { name: 'Equatorial Guinea', countryCode: 'GQ', dialCode: '+240' },
  { name: 'Eritrea', countryCode: 'ER', dialCode: '+291' },
  { name: 'Estonia', countryCode: 'EE', dialCode: '+372' },
  { name: 'Ethiopia', countryCode: 'ET', dialCode: '+251' },
  {
    name: 'Falkland Islands (Malvinas)',
    countryCode: 'FK',
    dialCode: '+500',
  },
  { name: 'Faroe Islands', countryCode: 'FO', dialCode: '+298' },
  { name: 'Fiji', countryCode: 'FJ', dialCode: '+679' },
  { name: 'Finland', countryCode: 'FI', dialCode: '+358' },
  { name: 'France', countryCode: 'FR', dialCode: '+33' },
  { name: 'French Guiana', countryCode: 'GF', dialCode: '+594' },
  { name: 'French Polynesia', countryCode: 'PF', dialCode: '+689' },
  {
    name: 'French Southern Territories',
    countryCode: 'TF',
    dialCode: '+262',
  },
  { name: 'Gabon', countryCode: 'GA', dialCode: '+241' },
  { name: 'Gambia', countryCode: 'GM', dialCode: '+220' },
  { name: 'Georgia', countryCode: 'GE', dialCode: '+995' },
  {
    name: 'Germany',
    countryCode: 'DE',
    dialCode: '+49',
  },
  { name: 'Ghana', countryCode: 'GH', dialCode: '+233' },
  { name: 'Gibraltar', countryCode: 'GI', dialCode: '+350' },
  { name: 'Greece', countryCode: 'GR', dialCode: '+30' },
  { name: 'Greenland', countryCode: 'GL', dialCode: '+299' },
  { name: 'Grenada', countryCode: 'GD', dialCode: '+1-473' },
  { name: 'Guadeloupe', countryCode: 'GP', dialCode: '+590' },
  { name: 'Guam', countryCode: 'GU', dialCode: '+1-671' },
  { name: 'Guatemala', countryCode: 'GT', dialCode: '+502' },
  { name: 'Guernsey', countryCode: 'GG', dialCode: '+44' },
  { name: 'Guinea-Bissau', countryCode: 'GW', dialCode: '+245' },
  { name: 'Guinea', countryCode: 'GN', dialCode: '+224' },
  { name: 'Guyana', countryCode: 'GY', dialCode: '+592' },
  { name: 'Haiti', countryCode: 'HT', dialCode: '+509' },
  {
    name: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
    dialCode: '+672',
  },
  {
    name: 'Holy See (Vatican City State)',
    countryCode: 'VA',
    dialCode: '+379',
  },
  { name: 'Honduras', countryCode: 'HN', dialCode: '+504' },
  { name: 'Hong Kong', countryCode: 'HK', dialCode: '+852' },
  { name: 'Hungary', countryCode: 'HU', dialCode: '+36' },
  { name: 'Iceland', countryCode: 'IS', dialCode: '+354' },
  { name: 'India', countryCode: 'IN', dialCode: '+91' },
  { name: 'Indonesia', countryCode: 'ID', dialCode: '+62' },
  {
    name: 'Iran, Islamic Republic of',
    countryCode: 'IR',
    dialCode: '+98',
  },
  { name: 'Iraq', countryCode: 'IQ', dialCode: '+964' },
  { name: 'Ireland', countryCode: 'IE', dialCode: '+353' },
  { name: 'Isle of Man', countryCode: 'IM', dialCode: '+44' },
  { name: 'Israel', countryCode: 'IL', dialCode: '+972' },
  { name: 'Italy', countryCode: 'IT', dialCode: '+39' },
  { name: 'Jamaica', countryCode: 'JM', dialCode: '+1-876' },
  { name: 'Japan', countryCode: 'JP', dialCode: '+81' },
  { name: 'Jersey', countryCode: 'JE', dialCode: '+44' },
  { name: 'Jordan', countryCode: 'JO', dialCode: '+962' },
  { name: 'Kazakhstan', countryCode: 'KZ', dialCode: '+7' },
  { name: 'Kenya', countryCode: 'KE', dialCode: '+254' },
  { name: 'Kiribati', countryCode: 'KI', dialCode: '+686' },
  {
    name: "Korea, Democratic People's Republic of",
    countryCode: 'KP',
    dialCode: '+850',
  },
  { name: 'Korea, Republic of', countryCode: 'KR', dialCode: '+82' },
  { name: 'Kosovo', countryCode: 'XK', dialCode: '+383' },
  { name: 'Kuwait', countryCode: 'KW', dialCode: '+965' },
  { name: 'Kyrgyzstan', countryCode: 'KG', dialCode: '+996' },
  {
    name: "Lao People's Democratic Republic",
    countryCode: 'LA',
    dialCode: '+856',
  },
  { name: 'Latvia', countryCode: 'LV', dialCode: '+371' },
  { name: 'Lebanon', countryCode: 'LB', dialCode: '+961' },
  { name: 'Lesotho', countryCode: 'LS', dialCode: '+266' },
  { name: 'Liberia', countryCode: 'LR', dialCode: '+231' },
  { name: 'Libya', countryCode: 'LY', dialCode: '+218' },
  { name: 'Liechtenstein', countryCode: 'LI', dialCode: '+423' },
  { name: 'Lithuania', countryCode: 'LT', dialCode: '+370' },
  { name: 'Luxembourg', countryCode: 'LU', dialCode: '+352' },
  { name: 'Macao', countryCode: 'MO', dialCode: '+853' },
  {
    name: 'Macedonia, the Former Yugoslav Republic of',
    countryCode: 'MK',
    dialCode: '+389',
  },
  { name: 'Madagascar', countryCode: 'MG', dialCode: '+261' },
  { name: 'Malawi', countryCode: 'MW', dialCode: '+265' },
  { name: 'Malaysia', countryCode: 'MY', dialCode: '+60' },
  { name: 'Maldives', countryCode: 'MV', dialCode: '+960' },
  { name: 'Mali', countryCode: 'ML', dialCode: '+223' },
  { name: 'Malta', countryCode: 'MT', dialCode: '+356' },
  { name: 'Marshall Islands', countryCode: 'MH', dialCode: '+692' },
  { name: 'Martinique', countryCode: 'MQ', dialCode: '+596' },
  { name: 'Mauritania', countryCode: 'MR', dialCode: '+222' },
  { name: 'Mauritius', countryCode: 'MU', dialCode: '+230' },
  { name: 'Mayotte', countryCode: 'YT', dialCode: '+262' },
  { name: 'Mexico', countryCode: 'MX', dialCode: '+52' },
  {
    name: 'Micronesia, Federated States of',
    countryCode: 'FM',
    dialCode: '+691',
  },
  { name: 'Moldova, Republic of', countryCode: 'MD', dialCode: '+373' },
  { name: 'Monaco', countryCode: 'MC', dialCode: '+377' },
  { name: 'Mongolia', countryCode: 'MN', dialCode: '+976' },
  { name: 'Montenegro', countryCode: 'ME', dialCode: '+382' },
  { name: 'Montserrat', countryCode: 'MS', dialCode: '+1-664' },
  { name: 'Morocco', countryCode: 'MA', dialCode: '+212' },
  { name: 'Mozambique', countryCode: 'MZ', dialCode: '+258' },
  { name: 'Myanmar', countryCode: 'MM', dialCode: '+95' },
  { name: 'Namibia', countryCode: 'NA', dialCode: '+264' },
  { name: 'Nauru', countryCode: 'NR', dialCode: '+674' },
  { name: 'Nepal', countryCode: 'NP', dialCode: '+977' },
  { name: 'Netherlands', countryCode: 'NL', dialCode: '+31' },
  { name: 'New Caledonia', countryCode: 'NC', dialCode: '+687' },
  { name: 'New Zealand', countryCode: 'NZ', dialCode: '+64' },
  { name: 'Nicaragua', countryCode: 'NI', dialCode: '+505' },
  { name: 'Niger', countryCode: 'NE', dialCode: '+227' },
  { name: 'Nigeria', countryCode: 'NG', dialCode: '+234' },
  { name: 'Norfolk Island', countryCode: 'NF', dialCode: '+672' },
  {
    name: 'Northern Mariana Islands',
    countryCode: 'MP',
    dialCode: '+1-670',
  },
  { name: 'Norway', countryCode: 'NO', dialCode: '+47' },
  { name: 'Oman', countryCode: 'OM', dialCode: '+968' },
  { name: 'Pakistan', countryCode: 'PK', dialCode: '+92' },
  { name: 'Palau', countryCode: 'PW', dialCode: '+680' },
  { name: 'Palestine, State of', countryCode: 'PS', dialCode: '+970' },
  { name: 'Panama', countryCode: 'PA', dialCode: '+507' },
  { name: 'Papua New Guinea', countryCode: 'PG', dialCode: '+675' },
  { name: 'Paraguay', countryCode: 'PY', dialCode: '+595' },
  { name: 'Peru', countryCode: 'PE', dialCode: '+51' },
  { name: 'Philippines', countryCode: 'PH', dialCode: '+63' },
  { name: 'Poland', countryCode: 'PL', dialCode: '+48' },
  { name: 'Portugal', countryCode: 'PT', dialCode: '+351' },
  { name: 'Puerto Rico', countryCode: 'PR', dialCode: '+1-787' },
  { name: 'Qatar', countryCode: 'QA', dialCode: '+974' },
  { name: 'Reunion', countryCode: 'RE', dialCode: '+262' },
  { name: 'Romania', countryCode: 'RO', dialCode: '+40' },
  { name: 'Russian Federation', countryCode: 'RU', dialCode: '+7' },
  { name: 'Rwanda', countryCode: 'RW', dialCode: '+250' },
  { name: 'Saba', countryCode: 'BQ', dialCode: '+599-4' },
  { name: 'Saint Barthelemy', countryCode: 'BL', dialCode: '+590' },
  { name: 'Saint Helena', countryCode: 'SH', dialCode: '+290' },
  { name: 'Saint Lucia', countryCode: 'LC', dialCode: '+1-758' },
  {
    name: 'Saint Martin (French part)',
    countryCode: 'MF',
    dialCode: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    dialCode: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    dialCode: '+1-784',
  },
  { name: 'Samoa', countryCode: 'WS', dialCode: '+685' },
  { name: 'San Marino', countryCode: 'SM', dialCode: '+378' },
  { name: 'Sao Tome and Principe', countryCode: 'ST', dialCode: '+239' },
  { name: 'Saudi Arabia', countryCode: 'SA', dialCode: '+966' },
  { name: 'Senegal', countryCode: 'SN', dialCode: '+221' },
  { name: 'Serbia', countryCode: 'RS', dialCode: '+381' },
  { name: 'Seychelles', countryCode: 'SC', dialCode: '+248' },
  { name: 'Sierra Leone', countryCode: 'SL', dialCode: '+232' },
  { name: 'Singapore', countryCode: 'SG', dialCode: '+65' },
  { name: 'Sint Eustatius', countryCode: 'BQ', dialCode: '+599-3' },
  {
    name: 'Sint Maarten (Dutch part)',
    countryCode: 'SX',
    dialCode: '+1-721',
  },
  { name: 'Slovakia', countryCode: 'SK', dialCode: '+421' },
  { name: 'Slovenia', countryCode: 'SI', dialCode: '+386' },
  { name: 'Solomon Islands', countryCode: 'SB', dialCode: '+677' },
  { name: 'Somalia', countryCode: 'SO', dialCode: '+252' },
  { name: 'South Africa', countryCode: 'ZA', dialCode: '+27' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    dialCode: '+500',
  },
  { name: 'South Sudan', countryCode: 'SS', dialCode: '+211' },
  { name: 'Spain', countryCode: 'ES', dialCode: '+34' },
  { name: 'Sri Lanka', countryCode: 'LK', dialCode: '+94' },
  { name: 'Sudan', countryCode: 'SD', dialCode: '+249' },
  { name: 'Suriname', countryCode: 'SR', dialCode: '+597' },
  { name: 'Svalbard and Jan Mayen', countryCode: 'SJ', dialCode: '+47' },
  { name: 'Swaziland', countryCode: 'SZ', dialCode: '+268' },
  { name: 'Sweden', countryCode: 'SE', dialCode: '+46' },
  { name: 'Switzerland', countryCode: 'CH', dialCode: '+41' },
  { name: 'Syrian Arab Republic', countryCode: 'SY', dialCode: '+963' },
  {
    name: 'Taiwan, Province of China',
    countryCode: 'TW',
    dialCode: '+886',
  },
  { name: 'Thailand', countryCode: 'TH', dialCode: '+66' },
  { name: 'Timor-Leste', countryCode: 'TL', dialCode: '+670' },
  { name: 'Togo', countryCode: 'TG', dialCode: '+228' },
  { name: 'Tokelau', countryCode: 'TK', dialCode: '+690' },
  { name: 'Tonga', countryCode: 'TO', dialCode: '+676' },
  { name: 'Trinidad and Tobago', countryCode: 'TT', dialCode: '+1-868' },
  { name: 'Tunisia', countryCode: 'TN', dialCode: '+216' },
  { name: 'Turkey', countryCode: 'TR', dialCode: '+90' },
  { name: 'Turkmenistan', countryCode: 'TM', dialCode: '+993' },
  {
    name: 'Turks and Caicos Islands',
    countryCode: 'TC',
    dialCode: '+1-649',
  },
  { name: 'Tuvalu', countryCode: 'TV', dialCode: '+688' },
  { name: 'Uganda', countryCode: 'UG', dialCode: '+256' },
  { name: 'Ukraine', countryCode: 'UA', dialCode: '+380' },
  { name: 'United Arab Emirates', countryCode: 'AE', dialCode: '+971' },
  { name: 'United Kingdom', countryCode: 'GB', dialCode: '+44' },
  {
    name: 'United Republic of Tanzania',
    countryCode: 'TZ',
    dialCode: '+255',
  },
  { name: 'United States', countryCode: 'US', dialCode: '+1' },
  { name: 'Uruguay', countryCode: 'UY', dialCode: '+598' },
  { name: 'US Virgin Islands', countryCode: 'VI', dialCode: '+1-340' },
  { name: 'Uzbekistan', countryCode: 'UZ', dialCode: '+998' },
  { name: 'Vanuatu', countryCode: 'VU', dialCode: '+678' },
  { name: 'Venezuela', countryCode: 'VE', dialCode: '+58' },
  { name: 'Vietnam', countryCode: 'VN', dialCode: '+84' },
  { name: 'Wallis and Futuna', countryCode: 'WF', dialCode: '+681' },
  { name: 'Western Sahara', countryCode: 'EH', dialCode: '+212' },
  { name: 'Yemen', countryCode: 'YE', dialCode: '+967' },
  { name: 'Zambia', countryCode: 'ZM', dialCode: '+260' },
  { name: 'Zimbabwe', countryCode: 'ZW', dialCode: '+263' },
];

export default DIAL_CODES;
