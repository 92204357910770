const refreshTokenManager = () => {
  let refreshTokenPromise: Promise<any> | null = null;

  const setPromise = (promise: Promise<any>) => {
    refreshTokenPromise = promise;
  };

  const erasePromise = () => {
    refreshTokenPromise = null;
  };

  const getPromise = () => refreshTokenPromise;

  return {
    setPromise,
    erasePromise,
    getPromise,
  };
};

const refreshTokenPromiseManager = refreshTokenManager();

export default refreshTokenPromiseManager;
