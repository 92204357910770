const CURRENCIES = [
  {
    value: 'EUR',
    text: '€ - Euro',
  },
  {
    value: 'USD',
    text: '$ - Dolar',
  },
  {
    value: 'GBP',
    text: '£ - British Pound Sterling',
  },
];

export default CURRENCIES;
