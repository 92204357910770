import { FC, memo, useCallback } from 'react';
import { Container, Logo, Wrapper, StyledSelect } from './PublicLayout.styles';
import { IPublicLayout } from './PublicLayout.types';
import { Outlet } from 'react-router-dom';
import constants from '@/constants/';
import { useLanguage } from '@/hooks/Language';
import { FlagESIcon, FlagITIcon, FlagPTIcon, FlagUSIcon } from '@/icons';

const { LANGUAGES } = constants;

const PublicLayout: FC<IPublicLayout> = () => {
  const { selectedLng, setSelectedLng } = useLanguage();

  const getIcon = useCallback((code: string) => {
    switch (code) {
      case 'en':
        return <FlagUSIcon />;
      case 'it':
        return <FlagITIcon />;
      case 'es':
        return <FlagESIcon />;
      default:
        return <FlagPTIcon />;
    }
  }, []);

  const parsedLanguage = LANGUAGES.filter((language) => language.code).map(
    (language) => {
      return {
        value: language.code!,
        text: language.name!,
        icon: getIcon(language.code),
      };
    }
  );

  const handleLanguageChange = useCallback(
    (value: string) => {
      setSelectedLng(value);
    },
    [setSelectedLng]
  );

  return (
    <Wrapper>
      <Container>
        <Logo src="/images/insparya_logo.svg" alt="logo insparya" />
        <Outlet />
        <StyledSelect
          $width="100%"
          onChange={(e) => handleLanguageChange(e.target.value as string)}
          name="language"
          value={selectedLng}
          options={parsedLanguage}
        />
      </Container>
    </Wrapper>
  );
};

export default memo(PublicLayout);
