import { ContentWrapper, StyledSnackbar } from './MessageFeedback.styles';
import { IMessageFeedbackProps } from './MessageFeedback.types';

const MessageFeedback = ({ text, open, onClose }: IMessageFeedbackProps) => {
  const content = <ContentWrapper>{text}</ContentWrapper>;

  return <StyledSnackbar open={open} onClick={onClose} message={content} />;
};

export default MessageFeedback;
