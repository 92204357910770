import styled from 'styled-components';
import { animation } from '@/styles/mixins';
import DropdownMenu from '@/components/DropdownMenu';
import Avatar from '@/components/Avatar';
import PrimaryButton from '@/components/PrimaryButton';
import BaseButton from '@/components/BaseButton';
import DeleteButton from '@/components/DeleteButton';

export const Container = styled.div`
  max-width: ${(props) => props.theme.breakpoints.xxl}px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 100%;
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 70px);
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.breakpoints.xxl}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  @media only screen and (min-width: 768px) {
    padding: 5px 20px 0 20px;
  }
`;

export const SuspenseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
`;

export const MicroButton = styled.button`
  background-color: ${(props) => props.theme.palette.common.pageBackground};
  border-radius: 8px;
  min-width: 30px;
  min-height: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0);
  ${animation({ time: '.3s' })}

  &:hover {
    box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.25);
  }
`;

export const Box = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.palette.white};
  padding: 15px;
  border: 1px solid ${(props) => props.theme.palette.common.greyBorder};
  border-radius: 8px;
  overflow: hidden;
`;

export const FormBox = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  padding: 30px 20px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.common.greyBorder};
  border-radius: 8px;
  text-align: right;
  margin-bottom: 20px;
`;

export const Divider = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.palette.common.greyBorder};
  border: none;
  margin: 10px 0;
  width: 100% !important;
`;

export const StyledMicroButton = styled(MicroButton)`
  margin-right: 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionsDropdownMenu = styled(DropdownMenu)`
  margin-left: 10px;
`;

export const PageActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  z-index: 1099;
  position: relative;
  min-height: 36px;

  ${StyledMicroButton} {
    display: none;
  }

  ${ActionsDropdownMenu} {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    ${StyledMicroButton} {
      display: block;
    }
    ${ActionsDropdownMenu} {
      display: none;
    }
  }
`;

export const TableAvatar = styled(Avatar)`
  margin: 0 auto;
  border: none;
  img {
    border: none;
  }
`;

export const SubModulesContainer = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-right: 15px;
    display: block;
  }
`;

export const SubModuleButton = styled(PrimaryButton)`
  margin-right: 5px;
`;

export const FormActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important;
  margin: 0 !important;
  padding: 30px;
  background: rgb(247, 248, 252);
  background: linear-gradient(
    0deg,
    rgba(247, 248, 252, 1) 60%,
    rgba(247, 248, 252, 0) 100%
  );
  min-height: 130px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  z-index: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 40px;
    justify-content: flex-end;
  }
`;

export const FormPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  max-width: fit-content;
  font-size: 16px;
  pointer-events: visible;
`;

export const FormBaseButton = styled(BaseButton)`
  width: 100%;
  max-width: fit-content;
  font-size: 16px;
  pointer-events: visible;
`;

export const FormDeleteButton = styled(DeleteButton)`
  width: 100%;
  max-width: fit-content;
  font-size: 16px;
  margin-right: 15px;
  margin-top: 10px;
  pointer-events: visible;
`;

export const FormTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: left;
  width: 100%;
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    `
    @media (min-width: ${props.theme.breakpoints.md}px) {
      flex-direction: row;
    }
  `}
`;

export const FormLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const RelationLinksWrapper = styled.div<{ $columns?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  & > div {
    margin-bottom: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    flex-direction: row;
    & > div {
      margin-right: 20px;
      width: calc(
        ${(props) => (props.$columns ? `${100 / props.$columns}%` : '100%')} -
          20px
      );
    }
  }
`;
