import styled from 'styled-components';
import MenuList from '@/components/MenuList';

export const StyledMenuList = styled(MenuList)`
  ul {
    padding: 0;
    li {
      .MuiListItemButton-root {
        > span,
        > a {
          padding: 5px 15px;
          .MuiListItemIcon-root {
            min-width: 30px;
          }
          &:hover {
            background-color: #f0f0f0;
            .MuiTypography-root {
              color: #a4a6b3;
            }
            .MuiListItemIcon-root {
              svg {
                fill: #a4a6b3 !important;
              }
            }
          }
          &:after {
            content: none;
          }

          div {
            span {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
`;

export const Wrapper = styled.div``;

export const Button = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
`;
