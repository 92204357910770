import { IUser, IPermissionGateItem } from '@/types';
import { checkForPermissions } from '@/utils/Misc';
import { useAuth } from '../Auth';
import { useEffect, useState } from 'react';
import { useBusinessUnit } from '../BusinessUnit';

const filterList = (
  moduleList: IPermissionGateItem[],
  userData: IUser | undefined,
  businessUnit: string
) => {
  if (!userData) return [];

  if (userData.userType?.name === 'SUPER_ADMIN') return moduleList;

  const permissions = userData.userPermissions?.find(
    (x) => x.businessUnit?.code === businessUnit && x.enabled
  )?.permissions;

  if (!permissions) return [];

  return moduleList.filter((module) => {
    const permission = module.requiredPermission;
    const modulePaths = permission?.allowedModules;
    const allowedAction = permission?.allowedAction;
    if (module.submenu) {
      module.submenu = filterList(module.submenu, userData, businessUnit);
    }
    if (module.submenu && module.submenu.length === 0) return false;
    if (!modulePaths || modulePaths.length === 0) return true;
    if (!allowedAction || allowedAction === 'none') return false;
    return checkForPermissions(
      modulePaths,
      permissions || {},
      allowedAction,
      permission.operator || 'AND'
    );
  });
};

const usePermissionGate = <T = any>(moduleList: IPermissionGateItem[]) => {
  const { user } = useAuth();
  const { selectedBusinessUnit } = useBusinessUnit();

  const userData = user?.data;

  const [list] = useState(moduleList);

  const [filteredList, setFilteredList] = useState(
    filterList(list, userData, selectedBusinessUnit)
  );

  useEffect(() => {
    setFilteredList(filterList(list, userData, selectedBusinessUnit));
  }, [list, selectedBusinessUnit, userData]);

  return filteredList as T[];
};

export default usePermissionGate;
