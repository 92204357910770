import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import FormComponentWrapper from '@/components/FormComponentWrapper';

export const StyledFormComponentWrapper = styled(FormComponentWrapper)<{
  $checked: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  > label {
    margin-bottom: 0;
  }
  > div:last-child {
    width: 100%;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
    margin-right: 5px;
    color: ${(props) => props.theme.palette.common.secundaryText};

    &.Mui-checked {
      color: ${(props) => props.theme.palette.common.main};
      border: 0;
    }
  }
`;
