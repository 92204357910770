import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const Partners = lazy(() => import('@/pages/Partners'));
const PartnersEdit = lazy(() => import('@/pages/PartnersEdit'));
const PartnersCreate = lazy(() => import('@/pages/PartnersCreate'));
const Clients = lazy(() => import('@/pages/PartnerClients'));
const PartnersClientsCreate = lazy(
  () => import('@/pages/PartnersClientsCreate')
);
const PartnersClientsEdit = lazy(() => import('@/pages/PartnersClientsEdit'));
const PartnersPendings = lazy(() => import('@/pages/PartnersPendings'));
const PartnersPendingsEdit = lazy(() => import('@/pages/PartnersPendingsEdit'));

const PartnersCategories = lazy(() => import('@/pages/PartnersCategories'));
const PartnersCategoriesCreate = lazy(
  () => import('@/pages/PartnersCategoriesCreate')
);
const PartnersCategoriesEdit = lazy(
  () => import('@/pages/PartnersCategoriesEdit')
);

const PartnersInvitations = lazy(() => import('@/pages/PartnersInvitations'));
const PartnersInvitationsEdit = lazy(
  () => import('@/pages/PartnersInvitationsEdit')
);

const PARTNERS_ROUTES = (
  <Route
    path="/dashboard/parceiros"
    element={<Outlet />}
    key="/dashboard/parceiros"
  >
    <Route path="/dashboard/parceiros" element={<Partners />} />
    <Route path="/dashboard/parceiros/create" element={<PartnersCreate />} />
    <Route path="/dashboard/parceiros/:id" element={<PartnersEdit />} />
    <Route path="/dashboard/parceiros/clientes" element={<Clients />} />
    <Route
      path="/dashboard/parceiros/clientes/create"
      element={<PartnersClientsCreate />}
    />
    <Route
      path="/dashboard/parceiros/clientes/:id"
      element={<PartnersClientsEdit />}
    />
    <Route
      path="/dashboard/parceiros/pendentes"
      element={<PartnersPendings />}
    />
    <Route
      path="/dashboard/parceiros/pendentes/:id"
      element={<PartnersPendingsEdit />}
    />
    <Route
      path="/dashboard/parceiros/categorias"
      element={<PartnersCategories />}
    />
    <Route
      path="/dashboard/parceiros/convites"
      element={<PartnersInvitations />}
    />
    <Route
      path="/dashboard/parceiros/convites/:id"
      element={<PartnersInvitationsEdit />}
    />
    <Route
      path="/dashboard/parceiros/categorias/create"
      element={<PartnersCategoriesCreate />}
    />
    <Route
      path="/dashboard/parceiros/categorias/:id"
      element={<PartnersCategoriesEdit />}
    />
  </Route>
);

export default PARTNERS_ROUTES;
