import { FC } from 'react';
import { WrapperLogoLoading } from './LogoLoading.styles';
import { ILogoLoading } from './LogoLoading.types';
const LogoLoading: FC<ILogoLoading> = ({ color }) => {
  return (
    <WrapperLogoLoading>
      <img
        src={
          color === 'primary'
            ? '/images/insparya_logo.svg'
            : '/images/insparya_white_logo.svg'
        }
        alt="logo insparya loading"
        width="200px"
      />
    </WrapperLogoLoading>
  );
};

export default LogoLoading;
