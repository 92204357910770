import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const Contratcs = lazy(() => import('@/pages/Contracts'));
const Gallery = lazy(() => import('@/pages/Gallery'));
const News = lazy(() => import('@/pages/News'));
const NewsCreate = lazy(() => import('@/pages/NewsCreate'));
const NewsEdit = lazy(() => import('@/pages/NewsEdit'));

const Services = lazy(() => import('@/pages/Services'));
const ServicesCreate = lazy(() => import('@/pages/ServicesCreate'));
const ServicesEdit = lazy(() => import('@/pages/ServicesEdit'));

const Notifications = lazy(() => import('@/pages/Notifications'));
const NotificationsCreate = lazy(() => import('@/pages/NotificationsCreate'));
const NotificationsEdit = lazy(() => import('@/pages/NotificationsEdit'));

const Campaigns = lazy(() => import('@/pages/Campaigns'));
const CampaignsCreate = lazy(() => import('@/pages/CampaignsCreate'));
const CampaignsEdit = lazy(() => import('@/pages/CampaignsEdit'));

const Chat = lazy(() => import('@/pages/Chat'));

const CONTRACT_ROUTE = (
  <Route
    key="/dashboard/contrato"
    path="/dashboard/contrato"
    element={<Contratcs />}
  />
);

const CHAT_ROUTE = (
  <Route key="/dashboard/chat" path="/dashboard/chat" element={<Chat />} />
);

const GALLERY_ROUTE = (
  <Route
    key="/dashboard/galeria"
    path="/dashboard/galeria"
    element={<Gallery />}
  />
);

const NEWS_ROUTE = (
  <Route
    path="/dashboard/noticias"
    element={<Outlet />}
    key="/dashboard/noticias"
  >
    <Route path="/dashboard/noticias" element={<News />} />
    <Route path="/dashboard/noticias/create" element={<NewsCreate />} />
    <Route path="/dashboard/noticias/:id" element={<NewsEdit />} />
  </Route>
);

const SERVICE_ROUTE = (
  <Route
    path="/dashboard/servicos"
    element={<Outlet />}
    key="/dashboard/servicos"
  >
    <Route path="/dashboard/servicos" element={<Services />} />
    <Route path="/dashboard/servicos/create" element={<ServicesCreate />} />
    <Route path="/dashboard/servicos/:id" element={<ServicesEdit />} />
  </Route>
);

const NOTIFICATIONS_ROUTE = (
  <Route
    path="/dashboard/notificacoes"
    element={<Outlet />}
    key="/dashboard/notificacoes"
  >
    <Route path="/dashboard/notificacoes" element={<Notifications />} />
    <Route
      path="/dashboard/notificacoes/create"
      element={<NotificationsCreate />}
    />
    <Route path="/dashboard/notificacoes/:id" element={<NotificationsEdit />} />
  </Route>
);

const CAMPAIGNS_ROUTE = (
  <Route
    path="/dashboard/campanhas"
    element={<Outlet />}
    key="/dashboard/campanhas"
  >
    <Route path="/dashboard/campanhas" element={<Campaigns />} />
    <Route path="/dashboard/campanhas/create" element={<CampaignsCreate />} />
    <Route path="/dashboard/campanhas/:id" element={<CampaignsEdit />} />
  </Route>
);

const ROUTES = [
  CONTRACT_ROUTE,
  GALLERY_ROUTE,
  NEWS_ROUTE,
  SERVICE_ROUTE,
  NOTIFICATIONS_ROUTE,
  CAMPAIGNS_ROUTE,
  CHAT_ROUTE,
];

export default ROUTES;
