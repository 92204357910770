import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  palette: {
    common: {
      main: '#D69B43',
      mainDark: '#484541',
      mainLight: '#FFEFD7',
      primaryText: '#383943',
      secundaryText: '#68717A',
      subText: '#9FA2B4',
      greyBorder: '#DFE0EB',
      pageBackground: '#F7F8FC',
      menu: '#363740',
      primary: '#F5B656',
    },
    white: '#FFFFFF',
    black: '#000000',
    darkGrey: '#252733',
    grey: '#585961',
    green: '#28A745',
    lightGreen: '#9DCF8B',
    red: '#DC3545',
    yellow: '#CFAA28',
    lightYellow: '#FFDE6A',
    blue: '#DDE2FF',
    primaryGradient: 'linear-gradient(180deg, #D69B43 0%, #F5B656 100%)',
    dangerGradient: 'linear-gradient(180deg, #d65143 0%, #f56756 100%)',
    lightGrey: '#CDCDCD',
    uploadBackground: '#F9F9F9',
    uploadItemBackground: '#F1F1F1',
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1100,
    xxl: 1440,
  },
  fontFamilies: {
    montserrat: "'Montserrat', sans-serif",
  },
};
