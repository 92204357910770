import PARTNERS_ROUTES from './Partners/Routes';
import COMMISSION_ROUTES from './Commissions/Routes';
import BACKOFFICE_MANAGEMENT_ROUTES from './BackofficeManagement/Routes';
import LEADS_ROUTES from './Leads/Routes';
import SALES_ROUTES from './Sales/Routes';
import APP_MANAGEMENT_ROUTES from './AppManagement/Routes';
import PARTNERS_MANAGEMENT_ROUTES from './PartnersManagement/Routes';
import RECOMMENDATION_ROUTES from './Recommendations';
import VOUCHER_ROUTES from './Vouchers';
import ASSIGMENT_PARTNERSHIP_ROUTES from '@/routes/Private/AssignmentPartnerships';

const PRIVATE_ROUTES = [
  PARTNERS_ROUTES,
  COMMISSION_ROUTES,
  BACKOFFICE_MANAGEMENT_ROUTES,
  LEADS_ROUTES,
  SALES_ROUTES,
  APP_MANAGEMENT_ROUTES,
  PARTNERS_MANAGEMENT_ROUTES,
  RECOMMENDATION_ROUTES,
  VOUCHER_ROUTES,
  ASSIGMENT_PARTNERSHIP_ROUTES,
];

export default PRIVATE_ROUTES;
