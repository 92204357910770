import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const PartnersManagement = lazy(() => import('@/pages/PartnersManagement'));
const PartnersManagementEdit = lazy(
  () => import('@/pages/PartnersManagementEdit')
);

const PARTNERS_MANAGEMENT_ROUTES = (
  <Route
    path="/dashboard/gestao-parceiros"
    element={<Outlet />}
    key="/dashboard/gestao-parceiros"
  >
    <Route
      path="/dashboard/gestao-parceiros"
      element={<PartnersManagement />}
    />
    <Route
      path="/dashboard/gestao-parceiros/:id"
      element={<PartnersManagementEdit />}
    />
  </Route>
);

export default PARTNERS_MANAGEMENT_ROUTES;
