import { useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@/components/Dialog';
import userSession from '@/utils/UserSession';
import { useBusinessUnit } from '@/hooks/BusinessUnit';
import SelectRedirectPath from '../SelectRedirectPath/SelectRedirectPath';

const SelectRedirectPathDialog = () => {
  const { t } = useTranslation();
  const { selectedBusinessUnit } = useBusinessUnit();

  const [open, setOpen] = useState(
    !userSession.getLoginRedirectPath(selectedBusinessUnit, {
      useDefaultIfEmpty: false,
    })
  );

  const [selectedPath, setSelectedPath] = useState(
    userSession.getLoginRedirectPath(selectedBusinessUnit) as string
  );

  const handleChangePath = useCallback((value: string) => {
    if (typeof value === 'string') {
      setSelectedPath(value);
    }
  }, []);

  const handleOnConfirm = useCallback(() => {
    userSession.setLoginRedirectPath(selectedBusinessUnit, selectedPath);
    setOpen(false);
  }, [selectedPath, selectedBusinessUnit]);

  return (
    <Dialog
      open={open}
      hideCloseAction
      title={t('components.select-redirect-path-dialog.title')}
      onConfirm={handleOnConfirm}
      content={
        <SelectRedirectPath
          defaultValue={userSession.getLoginRedirectPath(selectedBusinessUnit)}
          onChange={handleChangePath}
        />
      }
    />
  );
};

export default memo(SelectRedirectPathDialog);
