import styled from 'styled-components';

export const Wrapper = styled.div`
  ul {
    display: none;
    margin-top: 5px;

    @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
      display: initial;
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        &:first-child {
          display: initial;
        }

        a {
          font-weight: 400;
          color: ${(props) => props.theme.palette.common.secundaryText};
          text-decoration: none;

          svg {
            display: block;
            fill: ${(props) => props.theme.palette.common.secundaryText};
          }
        }
      }

      li {
        margin: 0 7px;
        position: relative;
        font-weight: 600;
        color: ${(props) => props.theme.palette.common.secundaryText};

        &:after {
          content: '/';
          position: absolute;
          right: -10px;
          top: 0;
          z-index: 1;
          width: 5px;
          height: 5px;
          color: ${(props) => props.theme.palette.common.secundaryText};
        }

        &:first-child {
          margin: 0;
        }

        &:last-child,
        &:first-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
`;
