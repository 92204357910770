import { FC } from 'react';
import BaseButton from '@/components/BaseButton';
import PrimaryButton from '@/components/PrimaryButton';
import Dialog from '@mui/material/Dialog';
import { IDialog } from './Dialog.types';
import { useMediaQuery } from '@mui/material';
import {
  StyledDialogActions,
  StyledDialogCloseOnTop,
  StyledDialogContent,
  Title,
} from './Dialog.styles';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const CustomDialog: FC<IDialog> = ({
  title,
  content,
  confirmText,
  closeText,
  open,
  onConfirm,
  onClose,
  loading = false,
  hideConfirmAction,
  showCloseButtonOnTop = false,
  hideCloseAction,
  centralizeTitle = false,
  actionsFullWidth = false,
  minWidth,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? '0' : '30px',
          padding: '20px 30px',
          maxWidth: 'initial',
          minWidth: minWidth ? minWidth : 'initial',
        },
      }}
      {...props}
    >
      {showCloseButtonOnTop && (
        <StyledDialogCloseOnTop>
          <Close onClick={onClose} />
        </StyledDialogCloseOnTop>
      )}
      <Title $centralize={centralizeTitle}>{title || ''}</Title>
      <StyledDialogContent>{content || ''}</StyledDialogContent>
      <StyledDialogActions $actionsFullWidth={actionsFullWidth}>
        {!hideCloseAction && (
          <BaseButton
            onClick={onClose}
            disabled={loading}
            fullWidth={actionsFullWidth}
          >
            {closeText || t('components.dialog.cancel')}
          </BaseButton>
        )}
        {!hideConfirmAction && (
          <PrimaryButton
            onClick={onConfirm}
            autoFocus
            disabled={loading}
            fullWidth={actionsFullWidth}
          >
            {confirmText || t('components.dialog.confirm')}
          </PrimaryButton>
        )}
      </StyledDialogActions>
    </Dialog>
  );
};

export default CustomDialog;
