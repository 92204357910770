import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

export const StyledMenuIcon = styled(MenuIcon)`
  color: ${(props) => props.theme.palette.common.primaryText};
`;

export const Wrapper = styled.div`
  display: flex;
  background-color: #f7f8fc;

  > main {
    padding-top: 100px;
    @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
      padding-top: 125px;
    }
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;

    @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
      flex-wrap: initial;
      padding-top: 0;
      > button {
        top: -10px;
      }
    }
    @media (min-width: 600px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
`;
