import styled, { keyframes } from 'styled-components';

const zoominout = keyframes`
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
`;

export const WrapperLogoLoading = styled.div`
  animation: ${zoominout} 1s infinite;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
