import styled from 'styled-components';
import MenuList from '@/components/MenuList';
import { animation } from '@/styles/mixins';

export const Wrapper = styled.div`
  .MuiListItemButton-root {
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    &:hover {
      background-color: transparent;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
      &:hover {
        ul {
          display: block;
        }
      }
    }
  }

  .MuiListItemIcon-root {
    min-width: 40px;
    svg {
      width: 20px;
      fill: #60626e;
      opacity: 0.7;
      ${animation({ time: '.3s' })}
    }
  }

  .MuiTypography-root {
    font-family: ${(props) => props.theme.fontFamilies.montserrat};
    color: #a4a6b3;
    font-size: 16px;
    ${animation({ time: '.3s' })}
  }

  a {
    position: relative;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    ${animation({ time: '.3s' })}
    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background-color: ${(props) => props.theme.palette.common.main};
      z-index: 1;
      opacity: 0;
      ${animation({ time: '.3s' })}
    }

    &.active,
    &:hover {
      background-color: #3e4049;
      &:after {
        opacity: 1;
      }

      .MuiTypography-root {
        color: #dde2ff;
        opacity: 1;
      }
      svg {
        fill: #dde2ff !important;
        opacity: 1 !important;
      }
    }
  }

  ul {
    background-color: ${(props) => props.theme.palette.common.menu};

    @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
      display: none;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`;

export const StyledMenuList = styled(MenuList)`
  ul {
    padding: 0;
    li {
      .MuiListItemButton-root {
        > span,
        > a {
          padding: 5px 15px;
          .MuiListItemIcon-root {
            min-width: 30px;
          }
          &:hover {
            background-color: #f0f0f0;
            .MuiTypography-root {
              color: #a4a6b3;
            }
            .MuiListItemIcon-root {
              svg {
                fill: #a4a6b3 !important;
              }
            }
          }
          &:after {
            content: none;
          }

          div {
            span {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
`;

export const StyledTitle = styled.span`
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  ${animation({ time: '.3s' })}
  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: ${(props) => props.theme.palette.common.main};
    z-index: 1;
    opacity: 0;
    ${animation({ time: '.3s' })}
  }
  &.active,
  &:hover {
    background-color: #3e4049;

    &:after {
      opacity: 1;
    }

    .MuiListItemIcon-root,
    .MuiTypography-root {
      color: #dde2ff;
      opacity: 1;
    }
    svg {
      fill: #dde2ff !important;
      opacity: 1 !important;
    }
  }
`;
