import { FC, useCallback, useEffect, useState } from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  Wrapper,
  HeaderTitle,
  Column,
  StyledAvatar,
  UsernameLabel,
  AvatarWithLabelLink,
  StyledSelect,
  Row,
} from './Header.styles';
import { IHeader } from './Header.types';
import { useBusinessUnit } from '@/hooks/BusinessUnit';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header: FC<IHeader> = ({ isHome, username, route, avatarUrl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { businessUnits, selectedBusinessUnit, setSelectedBusinessUnit } =
    useBusinessUnit();
  const parsedBusinessUnits = (businessUnits || [])
    .filter((bu) => bu.code)
    .map((bu) => {
      return {
        value: bu.code!,
        text: bu.code!,
      };
    });

  const [businessUnit, setBusinessUnit] =
    useState<string>(selectedBusinessUnit);

  const onChangeBusinessUnit = useCallback(
    (value: string) => {
      if (businessUnit !== value) {
        setBusinessUnit(value);
        setSelectedBusinessUnit && setSelectedBusinessUnit(value);

        const { pathname } = location;

        if (pathname === '/dashboard' || pathname === '/dashboard/') {
          window.location.reload();
        } else {
          navigate('/dashboard', { state: { from: location }, replace: true });
        }
      }
    },
    [businessUnit, location, navigate, setSelectedBusinessUnit]
  );

  useEffect(() => {
    setBusinessUnit(selectedBusinessUnit);
  }, [selectedBusinessUnit]);

  return (
    <Wrapper>
      <Column>
        {isHome ? (
          <HeaderTitle $isHome={isHome}>
            {t('header.hello')} <strong>{username || '- - -'}</strong>
          </HeaderTitle>
        ) : (
          <HeaderTitle $isHome={isHome}>
            {t(route?.title || '') || '- - -'}
          </HeaderTitle>
        )}
        {route && <Breadcrumbs route={route} />}
      </Column>
      <Row>
        {parsedBusinessUnits && parsedBusinessUnits.length > 1 && (
          <StyledSelect
            $width="100px"
            onChange={(e) => {
              e.stopPropagation();
              onChangeBusinessUnit(e.target.value as string);
            }}
            name="businessUnit"
            value={businessUnit}
            options={parsedBusinessUnits}
          />
        )}
        <AvatarWithLabelLink to="/dashboard/profile" $isHome={isHome}>
          <UsernameLabel>{username}</UsernameLabel>
          <StyledAvatar avatarUrl={avatarUrl} />
        </AvatarWithLabelLink>
      </Row>
    </Wrapper>
  );
};

export default Header;
