import { useState } from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledExpandMoreIcon,
  StyledTitle,
  StyledNavLink,
} from './ExpansionPanel.styles';
import { FC } from 'react';
import { IExpansionPanel } from './ExpansionPanel.types';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ExpansionPanel: FC<IExpansionPanel> = ({
  title,
  icon,
  children,
  className,
  url,
  open = false,
}) => {
  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleToogleExpandPanel = () => {
    setExpandedPanel(expandedPanel ? false : true);
  };

  const onClick = (e: any) => {
    const clickedOnTitle = e.target.localName === 'a';

    !clickedOnTitle && setExpandedPanel(!expandedPanel);
  };

  return (
    <StyledAccordion
      className={className}
      expanded={expandedPanel}
      defaultExpanded={open}
      sx={{ width: '100%' }}
    >
      <StyledAccordionSummary
        onClick={onClick}
        $open={expandedPanel}
        expandIcon={
          children && (
            <StyledExpandMoreIcon
              style={{
                color: expandedPanel ? '#E3E3E3' : '#000',
              }}
              onChange={handleToogleExpandPanel}
            />
          )
        }
      >
        {url && url !== '#' ? (
          <StyledNavLink
            to={url}
            className={({ isActive }) => (isActive ? 'active' : undefined)}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </StyledNavLink>
        ) : (
          <StyledTitle>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </StyledTitle>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ExpansionPanel;
