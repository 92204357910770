import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { animation } from '@/styles/mixins';

export const StyledNavLink = styled(NavLink)`
  && {
    &:after {
      content: initial;
    }
    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledTitle = styled.span`
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  ${animation({ time: '.3s' })}

  &.active,
  &:hover {
    .MuiListItemIcon-root,
    .MuiTypography-root {
      color: #dde2ff;
      opacity: 1;
    }

    svg {
      fill: #dde2ff !important;
      opacity: 1 !important;
    }
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
    border-bottom: none;
    ul {
      li {
        a {
          padding: 10px 0 10px 55px;
        }
      }
    }
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  && {
    width: 28px;
    height: 40px;
    right: 10px;
    fill: ${(props) => props.theme.palette.white};
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<{
  $open: boolean;
}>`
  && {
    background-color: transparent;
    border-bottom: none;
    min-height: initial !important;
    padding: 0;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background-color: ${(props) => props.theme.palette.common.main};
      z-index: 1;
      opacity: 0;
      ${animation({ time: '.3s' })}
    }

    > div {
      margin: 0;
      padding: 0;
    }

    > div:last-child {
      width: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background-color: #3e4049;
      &:after {
        opacity: 1;
      }
    }
  }
`;
