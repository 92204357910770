import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ILanguageDropdown } from './LanguageDropdown.types';
import {
  StyledMenuList,
  StyledTitle,
  Wrapper,
} from './LanguageDropdown.styles';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FlagESIcon, FlagITIcon, FlagPTIcon, FlagUSIcon } from '@/icons';
import constants from '@/constants/';

const { LANGUAGES } = constants;

const PAPER_PROPS = {
  sx: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
};

const MENU_LIST_PROPS = {
  'aria-labelledby': 'dropdown-button',
  sx: {
    backgroundColor: '#F8F8F8',
  },
};

const MENU_SX = { zIndex: 1299 };

interface ILanguageItem {
  code: string;
  text: string;
  icon?: ReactNode;
}

const LanguageDropdown: FC<ILanguageDropdown> = ({ lng, onSelect }) => {
  const getIcon = useCallback((code: string) => {
    switch (code) {
      case 'en':
        return <FlagUSIcon />;
      case 'it':
        return <FlagITIcon />;
      case 'es':
        return <FlagESIcon />;
      default:
        return <FlagPTIcon />;
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languages] = useState<ILanguageItem[]>(
    LANGUAGES.map((l) => {
      return {
        code: l.code,
        text: l.name,
        icon: getIcon(l.code),
      };
    })
  );
  const [selected, setSelected] = useState<ILanguageItem | undefined>();

  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const menu_languages = languages
    .filter((l) => l.code)
    .map((l) => {
      return {
        text: l.text,
        url: '#',
        icon: l.icon,
        onClick: () => {
          handleClose();

          onSelect && onSelect(l.code);
        },
      };
    });

  useEffect(() => {
    if (lng) {
      const l = languages.find((x) => x.code === lng);

      setSelected(l);
    }
  }, [languages, lng]);

  return (
    <Wrapper>
      <StyledTitle onClick={handleClick}>
        {selected?.icon && <ListItemIcon>{selected?.icon}</ListItemIcon>}
        <ListItemText primary={selected?.text} />
      </StyledTitle>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={MENU_SX}
        PaperProps={PAPER_PROPS}
        MenuListProps={MENU_LIST_PROPS}
      >
        <StyledMenuList menu={menu_languages} />
      </Menu>
    </Wrapper>
  );
};

export default LanguageDropdown;
