import styled from 'styled-components';
import { animation } from '@/styles/mixins';

export const ButtonBaseWrapper = styled.button<{
  $withGradient: boolean;
  $error: boolean;
  $fullWidth: boolean;
}>`
  background: ${(props) =>
    props.$withGradient
      ? props.theme.palette.primaryGradient
      : props.theme.palette.white};
  outline: none;
  border: ${(props) =>
    props.$withGradient
      ? 'none'
      : props.$error
      ? `1px solid ${props.theme.palette.red}`
      : `1px solid ${props.theme.palette.common.greyBorder}`};
  border-radius: 8px;
  color: ${(props) =>
    props.$withGradient
      ? props.theme.palette.white
      : props.$error
      ? props.theme.palette.red
      : props.theme.palette.common.secundaryText};
  cursor: pointer;
  font-weight: 500;
  padding: 9px 21px;
  font-size: 14px;
  ${animation({ time: '.1s' })}

  &:hover {
    box-shadow: ${(props) =>
      props.$withGradient
        ? '0px 0.5px 3px rgba(200, 141, 53, 0.85)'
        : props.$error
        ? '0px 0.5px 3px rgba(171, 16, 16, 0.25)'
        : '0px 0.5px 3px rgba(0, 0, 0, 0.25)'};
    font-weight: 600;
  }

  ${(props) => props.$fullWidth && 'width: 100%;'}
`;
