import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';

export const StyledSnackbar = styled(Snackbar)`
  & .MuiSnackbarContent-message {
    width: 100%;
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  margin-left: 8px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FeedbackContentWrapper = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    width: 32px;
    stroke: ${(props) => props.theme.palette.common.main};
  }
`;

export const ProgressWrapper = styled.div`
  font-size: 12px;
  & > span {
    margin-bottom: 4px;
    display: block;
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  width: 100%;
  height: 3px;
`;
