import styled from 'styled-components';
import Logo from '@/components/Logo';
import Divider from '@mui/material/Divider';
import LanguageDropdown from '../LanguageDropdown';

export const Menu = styled.nav``;

export const StyledLogo = styled(Logo)`
  padding: 40px 0 28px 0;
`;

export const Wrapper = styled.div``;

export const StyledDivider = styled(Divider)`
  && {
    border-color: #60626e;
  }
`;

export const StyledLanguageDropdown = styled(LanguageDropdown)``;
