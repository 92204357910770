import CancelIcon from '@mui/icons-material/Cancel';
import { ISelectOption, ISelect, ExtendedOption } from './Select.types';
import FormComponentWrapper from '@/components/FormComponentWrapper';
import Checkbox from '@/components/Checkbox';
import {
  Select as StyledSelect,
  MenuItem,
  StyledInput,
  Placeholder,
  Icon,
  StyledChip,
  StyledItemIcon,
} from './Select.styles';
import { useTranslation } from 'react-i18next';

export const isSelectOption = (option: any): option is ISelectOption =>
  typeof option?.value === 'string' && typeof option?.text === 'string';

const Select = <T extends ISelectOption>({
  value,
  name,
  options,
  error,
  footnote,
  tooltip,
  required,
  label,
  disabled,
  placeholder,
  multiple,
  noFootnote,
  className,
  onDelete,
  ...props
}: ISelect<T>) => {
  const { t } = useTranslation();
  const parsedValue = Array.isArray(value)
    ? value.map((v) => (typeof v === 'string' ? v : v.text))
    : typeof value === 'string'
    ? value
    : value?.value || (multiple ? [] : '');

  return (
    <FormComponentWrapper
      required={required}
      tooltip={tooltip}
      footnote={footnote}
      label={label}
      name={name}
      error={error}
      noFootnote={noFootnote}
      className={className}
    >
      <StyledSelect
        multiple={multiple}
        value={parsedValue}
        displayEmpty
        input={<StyledInput $error={!!error} name={name} />}
        name={name}
        required={required}
        disabled={disabled}
        IconComponent={Icon}
        MenuProps={{
          disablePortal: true,
        }}
        renderValue={(selected) => {
          const isArray = Array.isArray(selected);

          if (!selected || (isArray && selected.length === 0)) {
            return <Placeholder>{placeholder}</Placeholder>;
          }

          if (selected && typeof selected === 'string') {
            const selectedOption = options?.find(
              (option) => option.value === selected
            );
            return (
              <>
                {selectedOption?.icon && (
                  <StyledItemIcon>{selectedOption?.icon}</StyledItemIcon>
                )}
                {t(selectedOption?.text || selected)}
              </>
            );
          }

          if (isArray && multiple) {
            return selected.map((v: string) => {
              const selectedOption = options?.find(
                (option) => option.value === v
              ) as ExtendedOption<T>;

              return (
                <StyledChip
                  key={v}
                  label={t(selectedOption?.text || v)}
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={() => onDelete && onDelete(v)}
                />
              );
            });
          }

          if (isSelectOption(selected)) {
            return (
              <>
                {selected.icon && (
                  <StyledItemIcon>{selected.icon}</StyledItemIcon>
                )}
                {t(selected.text)}
              </>
            );
          }
        }}
        style={{ opacity: disabled ? '0.5' : '1' }}
        {...props}
      >
        <MenuItem value="" />
        {options &&
          options.map((option, index) => {
            return (
              <MenuItem
                $multiple={multiple}
                value={option.value}
                key={`${name}-${index}`}
                disabled={!!option.disabled}
              >
                {option.icon && <StyledItemIcon>{option.icon}</StyledItemIcon>}
                {multiple ? (
                  <Checkbox
                    name={`checkbox-${name}-${index}`}
                    label={t(option.text)}
                    checked={
                      !value
                        ? false
                        : value?.findIndex((value) => {
                            if (typeof value === 'string') {
                              return value === option.value;
                            } else {
                              return value.value === option.value;
                            }
                          }) >= 0
                    }
                    error={false}
                    noFootnote
                  />
                ) : (
                  t(option.text)
                )}
              </MenuItem>
            );
          })}
      </StyledSelect>
    </FormComponentWrapper>
  );
};

export default Select;
