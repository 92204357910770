import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  html, body, #root {
    height: fit-content;
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fontFamilies.montserrat};
  }

  *:focus, *:active, *:visited{
    outline: none;
  }

  input,
  button,
  select,
  textarea {
    &:disabled{
      opacity: 0.5;
      cursor: not-allowed;
      appearance: none;
    }
  }

  input:disabled.MuiSwitch-input {
    opacity: 0;
  }

  .Mui-disabled{
    opacity: 0.5;
    cursor: not-allowed;
    appearance: none;
  }

  iframe[style*="z-index: 2147483647"] {
    pointer-events: none
  }

  .grecaptcha-badge {
    display: none !important;
  }

  .react-datepicker {
    border: 1px solid ${(props) =>
      props.theme.palette.common.greyBorder} !important
  }

  .react-datepicker__header  {
    background-color: ${(props) =>
      props.theme.palette.common.pageBackground} !important
  }

  .react-datepicker__day--selected {
    background-color: ${(props) => props.theme.palette.common.main} !important
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: ${(props) => props.theme.palette.common.main} !important
  }

`;
