import styled from 'styled-components';
import MaterialMenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Chip from '@mui/material/Chip';
import { ListItemIcon } from '@mui/material';

export const StyledChip = styled(Chip)`
  && {
    height: initial;
    background-color: ${(props) => props.theme.palette.common.greyBorder};
    margin-right: 5px;
    padding: 1px;
  }
`;

export const Icon = styled(ArrowBackIosRoundedIcon)`
  transform: rotate(-90deg);
  &.MuiSelect-iconOpen {
    transform: rotate(90deg);
  }
  &.MuiSelect-icon {
    width: 15px;
    height: 15px;
    top: calc(50% - 5px);
  }
`;

export const Placeholder = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.palette.common.subText};
  font-size: 14px;
`;

export const StyledInput = styled(OutlinedInput)<{ $error: boolean }>`
  && {
    .MuiSelect-outlined {
      font-size: 14px;
      padding: 9px;
      padding-right: 32px !important;
      border: 1px solid
        ${(props) =>
          props.$error
            ? props.theme.palette.red
            : props.theme.palette.common.greyBorder};
      border-radius: 4px !important;
      color: ${(props) => props.theme.palette.common.primaryText}!important;
      &:focus {
        border-radius: 4px !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 4px !important;
    }
  }
`;

export const MenuItem = styled(MaterialMenuItem)<{ $multiple?: boolean }>`
  && {
    width: 100%;
    color: ${(props) => props.theme.palette.common.secundaryText};
    font-family: ${(props) => props.theme.fontFamilies.montserrat};
    font-weight: 400;
    font-size: 14px;
    padding: 9px;

    label {
      cursor: pointer;
    }

    &:first-child {
      display: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.Mui-selected {
      color: ${(props) => props.theme.palette.common.secundaryText};
      background-color: ${(props) =>
        props.$multiple ? props.theme.palette.white : 'rgba(0, 0, 0, 0.02)'};
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
`;

export const Select = styled(MaterialSelect)`
  && {
    position: relative;
    width: 100%;
    font-family: ${(props) => props.theme.fontFamilies.montserrat} !important;

    > input {
      opacity: 0;
    }

    > svg {
      color: rgb(138, 138, 138);
      top: calc(50% - 6px);
      right: 10px !important;
    }

    .MuiPaper-root {
      box-shadow: 0 0 0 1px #dfe0eb;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -2px;
      ul {
        background-color: #fff;
        margin-top: -1px;
      }
    }
  }

  .MuiListItemIcon-root {
    min-width: 40px;
    vertical-align: middle;
    svg {
      width: 20px;
    }
  }
`;

export const StyledItemIcon = styled(ListItemIcon)``;
