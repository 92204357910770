import { toast, ToastOptions } from 'react-toastify';
import { IToast } from './Toastify.type';

const emitAlert = ({ message, duration, type }: IToast) => {
  const defaultOptions: ToastOptions = {
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    position: 'bottom-right',
    draggable: false,
  };

  if (type !== undefined && type !== 'default') {
    toast[type](message, defaultOptions);
  } else {
    toast(message, defaultOptions);
  }
};

export default emitAlert;
