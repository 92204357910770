import { lazy } from 'react';
import { Route } from 'react-router-dom';

const RecommendationsCreate = lazy(
  () => import('@/pages/RecommendationsCreate')
);

const CHAT_ROUTE = (
  <Route
    key="/dashboard/criar-recomendacao"
    path="/dashboard/criar-recomendacao"
    element={<RecommendationsCreate />}
  />
);

const ROUTES = [CHAT_ROUTE];

export default ROUTES;
