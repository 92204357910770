import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IMenuListProps } from './MenuList.types';
import { FC, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuListWrapper, StyledTitle, StyledDivider } from './MenuList.styles';
import ExpansionPanel from '@/components/ExpansionPanel/';
import { useTranslation } from 'react-i18next';

const MenuList: FC<IMenuListProps> = ({
  menu,
  isSubmenu,
  isLastSubmenu,
  className,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <MenuListWrapper
      $isSubmenu={isSubmenu}
      className={className}
      $isLastSubmenu={isLastSubmenu}
    >
      <List>
        {menu.map(
          (
            { text, url, icon, divider, isLastSubmenu, onClick, submenu },
            menuIndex
          ) => {
            const parsedText = typeof text === 'string' ? t(text) : text;

            return (
              <Fragment key={`${text}-${menuIndex}`}>
                <ListItem key={`${text}-${menuIndex}`} disablePadding>
                  <ListItemButton>
                    {!submenu ? (
                      <Fragment>
                        {url && url !== '#' ? (
                          <NavLink
                            end
                            onClick={onClick}
                            to={url}
                            className={({ isActive }) =>
                              isActive ? 'active' : undefined
                            }
                          >
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText primary={parsedText} />
                          </NavLink>
                        ) : (
                          <StyledTitle onClick={onClick}>
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText primary={parsedText} />
                          </StyledTitle>
                        )}
                        {children &&
                          children(submenu && submenu, isLastSubmenu)}
                      </Fragment>
                    ) : (
                      <ExpansionPanel title={parsedText} url={url} icon={icon}>
                        {children &&
                          children(submenu && submenu, isLastSubmenu)}
                      </ExpansionPanel>
                    )}
                  </ListItemButton>
                </ListItem>
                {divider && <StyledDivider />}
              </Fragment>
            );
          }
        )}
      </List>
    </MenuListWrapper>
  );
};

export default MenuList;
