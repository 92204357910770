import styled from 'styled-components';
import Select from '@/components/Select';

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f8fc;
`;

export const Logo = styled.img`
  margin: 20px auto;
  width: 100%;
  max-width: 190px;
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  padding: 30px 20px 40px 20px;
  width: 100%;
  max-width: 350px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.palette.common.greyBorder};
  border-radius: 8px;
`;

export const StyledSelect = styled(Select)<{ $width: string }>`
  max-width: 265px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    width: ${(props) => props.$width}!important;
  }
`;
