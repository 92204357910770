import { VoucherPackProvider } from '@/contexts/VoucherPack';
import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

const VoucherPacks = lazy(() => import('@/pages/VoucherPacks'));
const VoucherPacksCreate = lazy(() => import('@/pages/VoucherPacksCreate'));
const VoucherPacksDetail = lazy(() => import('@/pages/VoucherPacksDetail'));

const VOUCHER_ROUTES = (
  <Route
    path="/dashboard/voucher-packs"
    element={
      <VoucherPackProvider>
        <Outlet />
      </VoucherPackProvider>
    }
    key="/dashboard/voucher-packs"
  >
    <Route path="/dashboard/voucher-packs" element={<VoucherPacks />} />
    <Route
      path="/dashboard/voucher-packs/create"
      element={<VoucherPacksCreate />}
    />
    <Route
      path="/dashboard/voucher-packs/:id"
      element={<VoucherPacksDetail />}
    />
  </Route>
);

export default VOUCHER_ROUTES;
