import { Suspense, lazy, memo } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import PrivateLayout from './layouts/PrivateLayout';
import PublicLayout from './layouts/PublicLayout';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import { SuspenseWrapper } from '@/styles/shared';
import LogoLoading from './components/LogoLoading';
import PUBLIC_ROUTES from './routes/Public/Routes';
import PRIVATE_ROUTES from './routes/Private';
import { ExportProvider } from './contexts/Export';
import { BusinessUnitProvider } from './contexts/BusinessUnit';

const Dashboard = lazy(() => import('@/pages/Dashboard'));
const DashboardNotFound = lazy(() => import('@/components/DashboardNotFound'));

const ProvidersWrapper = memo(() => (
  <BusinessUnitProvider>
    <ExportProvider>
      <PrivateRoute />
    </ExportProvider>
  </BusinessUnitProvider>
));

const App = () => {
  return (
    <Suspense
      fallback={
        <SuspenseWrapper>
          <LogoLoading color="primary" />
        </SuspenseWrapper>
      }
    >
      <Routes>
        <Route element={<PublicRoute />}>
          <Route element={<PublicLayout />}>{PUBLIC_ROUTES}</Route>
        </Route>
        <Route element={<ProvidersWrapper />}>
          <Route element={<PrivateLayout />}>
            <Route path="/dashboard/" element={<Outlet />}>
              {PRIVATE_ROUTES}
              <Route path="/dashboard/" element={<Dashboard />} />
            </Route>
            <Route path="/dashboard/*" element={<DashboardNotFound />} />
          </Route>
        </Route>
        <Route path="*" element={<DashboardNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
