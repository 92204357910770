import { ReactNode, createContext, useEffect, useState } from 'react';
import { IVoucherPackContext } from './VoucherPack.types';
import useAuth from '@/hooks/Auth';
import useSocket from '@/hooks/Socket';
import MessageFeedback from '@/components/MessageFeedback';
import { useTranslation } from 'react-i18next';

export const VoucherPackContext = createContext<IVoucherPackContext>({});

export const VoucherPackProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string>();

  const { message } = useSocket<any, { voucherPackName: string }>({
    nameSpace: 'voucher-packs',
    event: `${user?.data?.id}-voucher-packs-vouchers`,
    shouldConnect: true,
  });

  useEffect(() => {
    if (message) {
      setOpen(true);
      setText(
        t('voucher-pack.vouchers-created-success-message', {
          name: message.voucherPackName,
        })
      );
    }
  }, [message, t]);

  return (
    <VoucherPackContext.Provider value={{}}>
      {children}
      <MessageFeedback text={text} open={open} onClose={() => setOpen(false)} />
    </VoucherPackContext.Provider>
  );
};
