import { useEffect, useState, FC, ChangeEvent } from 'react';
import { ICheckbox } from './Checkbox.types';
import { StyledFormComponentWrapper, StyledCheckbox } from './Checkbox.styles';

const Checkbox: FC<ICheckbox> = ({
  error,
  footnote,
  tooltip,
  required,
  label,
  name,
  noFootnote = false,
  checked = false,
  onChange,
  className,
  ...props
}) => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedState(event.target.checked);
    onChange && onChange(event, event.target.checked);
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked, setCheckedState]);

  return (
    <StyledFormComponentWrapper
      required={required}
      tooltip={tooltip}
      footnote={footnote}
      label={label}
      name={name}
      error={error}
      noFootnote={noFootnote}
      $checked={checkedState}
      className={className}
    >
      <StyledCheckbox
        onChange={handleChange}
        checked={checkedState}
        {...props}
      />
    </StyledFormComponentWrapper>
  );
};

export default Checkbox;
