import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const Leads = lazy(() => import('@/pages/Leads'));
const LeadsEdit = lazy(() => import('@/pages/LeadsEdit'));

const LEADS_ROUTES = (
  <Route path="/dashboard/leads" element={<Outlet />} key="/dashboard/leads">
    <Route path="/dashboard/leads" element={<Leads />} />
    <Route path="/dashboard/leads/:id" element={<LeadsEdit />} />
  </Route>
);

export default LEADS_ROUTES;
