import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ITooltip } from './Tooltip.types';
import { Tooltip } from './Tooltip.styles';
import { useState } from 'react';

const CustomTooltip = ({ children, ...props }: ITooltip) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="top-end"
          {...props}
        >
          {children(handleTooltipOpen)}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CustomTooltip;
