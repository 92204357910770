import axios from '@/utils/Axios';
import useAuth from '@/hooks/Auth';
import inMemoryJwt from '@/utils/InMemoryJwt/InMemoryJwt';
import userSession from '@/utils/UserSession';
import refreshTokenPromiseManager from '@/utils/RefreshTokenPromise/';

const useRefreshToken = () => {
  const { setUser } = useAuth();

  const refresh = async () => {
    try {
      const refreshPromise = refreshTokenPromiseManager.getPromise();

      // if another request comes before the refresh token request is finished
      // it will return the same promise as it's not resolved yet and value is not set to null again
      // This will avoid requesting another refresh token while the previous request is still unfufilled
      if (refreshPromise) return refreshPromise;

      const refreshTokenRequestPromise = axios.post<{
        payload: { accessToken: string };
      }>('/api/refresh-token');

      refreshTokenPromiseManager.setPromise(refreshTokenRequestPromise);

      const [{ data }] = await Promise.all([refreshTokenRequestPromise]);

      const newAccessToken = data?.payload?.accessToken;

      if (!newAccessToken) {
        setUser(null);
        inMemoryJwt.eraseToken();
        userSession.remove();
        return null;
      } else {
        setUser((prev) => {
          refreshTokenPromiseManager.erasePromise();
          return { ...prev, accessToken: newAccessToken };
        });
        inMemoryJwt.setToken(newAccessToken);
        return newAccessToken;
      }
    } catch (e) {
      // console.error('refresh', e);
      setUser(null);
      inMemoryJwt.eraseToken();
      userSession.remove();
      refreshTokenPromiseManager.erasePromise();
    }
  };

  return refresh;
};

export default useRefreshToken;
