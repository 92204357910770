import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const GestaoUtilizadores = lazy(() => import('@/pages/Users'));
const GestaoUtilizadoresCreate = lazy(() => import('@/pages/UsersCreate'));
const GestaoUtilizadoresEdit = lazy(() => import('@/pages/UsersEdit'));

const BusinessUnits = lazy(() => import('@/pages/BusinessUnits'));
const BusinessUnitsCreate = lazy(() => import('@/pages/BusinessUnitsCreate'));
const BusinessUnitsEdit = lazy(() => import('@/pages/BusinessUnitsEdit'));

const AttributionRules = lazy(() => import('@/pages/AttributionRules'));
const AttributionRulesCreate = lazy(
  () => import('@/pages/AttributionRulesCreate')
);
const AttributionRulesEdit = lazy(() => import('@/pages/AttributionRulesEdit'));

const Policies = lazy(() => import('@/pages/Policies'));
const PoliciesCreate = lazy(() => import('@/pages/PoliciesCreate'));
const PoliciesEdit = lazy(() => import('@/pages/PoliciesEdit'));

const Profile = lazy(() => import('@/pages/Profile'));
const Parameters = lazy(() => import('@/pages/Parameters'));
const FieldLevelChangesLogs = lazy(
  () => import('@/pages/FieldLevelChangesLogs')
);
const VoucherLogs = lazy(() => import('@/pages/VoucherLogs'));

const USERS_ROUTES = (
  <Route
    path="/dashboard/gestao-utilizadores"
    element={<Outlet />}
    key="/dashboard/gestao-utilizadores"
  >
    <Route
      path="/dashboard/gestao-utilizadores"
      element={<GestaoUtilizadores />}
    />
    <Route
      path="/dashboard/gestao-utilizadores/create"
      element={<GestaoUtilizadoresCreate />}
    />
    <Route
      path="/dashboard/gestao-utilizadores/:id"
      element={<GestaoUtilizadoresEdit />}
    />
  </Route>
);

const PARAMETER_ROUTE = (
  <Route
    path="/dashboard/parametros"
    key="/dashboard/parametros"
    element={<Parameters />}
  />
);

const PROFILE_ROUTE = (
  <Route
    path="/dashboard/profile"
    key="/dashboard/profile"
    element={<Profile />}
  />
);

const FIELD_CHANGE_LOG_ROUTE = (
  <Route
    path="/dashboard/logs"
    key="/dashboard/logs"
    element={<FieldLevelChangesLogs />}
  />
);

const VOUCHER_LOGS_ROUTE = (
  <Route
    path="/dashboard/voucher-logs"
    key="/dashboard/voucher-logs"
    element={<VoucherLogs />}
  />
);

const BUSINESS_UNITS_ROUTE = (
  <Route
    path="/dashboard/unidades"
    element={<Outlet />}
    key="/dashboard/unidades"
  >
    <Route path="/dashboard/unidades" element={<BusinessUnits />} />
    <Route
      path="/dashboard/unidades/create"
      element={<BusinessUnitsCreate />}
    />
    <Route path="/dashboard/unidades/:id" element={<BusinessUnitsEdit />} />
  </Route>
);

const ATTRIBUTION_RULES_ROUTE = (
  <Route
    path="/dashboard/regras-atribuicao"
    element={<Outlet />}
    key="/dashboard/regras-atribuicao"
  >
    <Route path="/dashboard/regras-atribuicao" element={<AttributionRules />} />
    <Route
      path="/dashboard/regras-atribuicao/create"
      element={<AttributionRulesCreate />}
    />
    <Route
      path="/dashboard/regras-atribuicao/:id"
      element={<AttributionRulesEdit />}
    />
  </Route>
);

const POLICIES_ROUTE = (
  <Route
    path="/dashboard/politicas"
    element={<Outlet />}
    key="/dashboard/politicas"
  >
    <Route path="/dashboard/politicas" element={<Policies />} />
    <Route path="/dashboard/politicas/create" element={<PoliciesCreate />} />
    <Route path="/dashboard/politicas/:id" element={<PoliciesEdit />} />
  </Route>
);

const BACKOFFICE_MANAGEMENT_ROUTES = [
  USERS_ROUTES,
  PARAMETER_ROUTE,
  PROFILE_ROUTE,
  FIELD_CHANGE_LOG_ROUTE,
  VOUCHER_LOGS_ROUTE,
  BUSINESS_UNITS_ROUTE,
  ATTRIBUTION_RULES_ROUTE,
  POLICIES_ROUTE,
];

export default BACKOFFICE_MANAGEMENT_ROUTES;
