import BaseButton from '@/components/BaseButton';
import { IPrimaryButton } from './PrimaryButton.types';
import { FC } from 'react';

const PrimaryButton: FC<IPrimaryButton> = ({
  className,
  children,
  fullWidth = false,
  ...props
}) => {
  return (
    <BaseButton
      className={className}
      withGradient
      {...props}
      fullWidth={fullWidth}
    >
      {children}
    </BaseButton>
  );
};

export default PrimaryButton;
