import { IAnimation, IGadientStriped } from './types';

export const animation = ({
  time,
  property = 'all',
  type = 'ease',
}: IAnimation) => `
  -webkit-transition: ${property} ${time} ${type};
  -moz-transition: ${property} ${time} ${type};
  -ms-transition: ${property} ${time} ${type};
  -o-transition: ${property} ${time} ${type};
  transition: ${property} ${time} ${type};
`;

export const GradientStriped = ({
  color = 'rgba(255,255,255,.15)',
  angle = '45deg',
}: IGadientStriped) => `
  background-image: -webkit-linear-gradient(${angle}, ${color} 25%, transparent 25%, transparent 50%, ${color} 50%, ${color} 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(${angle}, ${color} 25%, transparent 25%, transparent 50%, ${color} 50%, ${color} 75%, transparent 75%, transparent);
  background-image: linear-gradient(${angle}, ${color} 25%, transparent 25%, transparent 50%, ${color} 50%, ${color} 75%, transparent 75%, transparent);
`;

export const FormElementCSS = ({
  width,
  mdBreakpoint,
}: {
  width: string;
  mdBreakpoint: number;
}) => `
  max-width: initial;
  margin-bottom: 5px;
  width: 100%;

  @media (min-width: ${mdBreakpoint}px) {
    width: ${width}!important;
  }
`;
