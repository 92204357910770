import {
  IModulePermissions,
  IModulePermissionsActions,
  IModuleKeys,
} from '@/types';

const matchHtmlRegExp = /["'&<>]/;

export const IsObjInArray = (
  targetObject: Record<any, any>,
  array: Record<any, any>[],
  comparisonKey: string
) => {
  const elementsCount = array.length;

  for (var i = 0; i < elementsCount; i++) {
    const element = array[i];

    if (element[comparisonKey] === targetObject[comparisonKey]) {
      return true;
    }
  }

  return false;
};

export const getPaginationSkip = (page: number, rowsPerPage: number) => {
  return page >= 1 ? rowsPerPage * page : 0;
};

export const isJson = (str: string | null) => {
  try {
    if (!str) return false;

    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isSameDay = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDay() === secondDate.getDay()
  );
};

export const escapeHtml = (string: string) => {
  let str = '' + string;
  const match = matchHtmlRegExp.exec(str);

  if (!match) {
    return str;
  }

  let escape = '';
  let html = '';
  let index = 0;
  let lastIndex = 0;

  for (index = match.index; index < str.length; index++) {
    switch (str.charCodeAt(index)) {
      case 34: // "
        escape = '&quot;';
        break;
      case 38: // &
        escape = '&amp;';
        break;
      case 39: // '
        escape = '&#39;';
        break;
      case 60: // <
        escape = '&lt;';
        break;
      case 62: // >
        escape = '&gt;';
        break;
      default:
        continue;
    }

    if (lastIndex !== index) {
      html += str.substring(lastIndex, index);
    }

    lastIndex = index + 1;
    html += escape;
  }

  return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
};

export const checkForPermissions = (
  modulesPath: IModuleKeys[],
  permissions: IModulePermissions,
  action: keyof IModulePermissionsActions,
  operator: 'AND' | 'OR'
) => {
  let arrayOfChecks = [];

  for (let modulePath of modulesPath) {
    const paths = modulePath.split('.');
    const levels = paths.length;

    let currentModules = permissions;

    for (let i = 0; i < levels; i++) {
      const path = paths[i];
      if (currentModules[path] && i === levels - 1) {
        arrayOfChecks.push(!!currentModules[path]?.actions[action]);
      } else {
        const subModules = permissions[path]?.subModules;

        if (subModules) {
          currentModules = subModules;
          continue;
        }

        arrayOfChecks.push(false);
      }
    }
  }

  const result = arrayOfChecks.reduce(
    (result, currentValue) => {
      return operator === 'AND'
        ? currentValue && result
        : currentValue || result;
    },
    operator === 'AND' ? true : false
  );

  return result || false;
};
