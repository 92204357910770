const IMAGE_MIME_TYPES = [
  'image/apng',
  'image/avif',
  'image/bmp',
  'image/gif',
  'image/vnd.microsoft.icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
];

const XLS_MIME_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export { IMAGE_MIME_TYPES, XLS_MIME_TYPES };
