const inMemoryJwt = () => {
  let JWT: string | null = null;

  const getToken = () => JWT;

  const setToken = (token: string) => {
    JWT = token;
  };

  const eraseToken = () => {
    JWT = null;
  };

  return {
    eraseToken,
    getToken,
    setToken,
  };
};

export default inMemoryJwt();
