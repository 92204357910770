import { useEffect, useState, useCallback, useMemo } from 'react';
import { io, Socket } from 'socket.io-client';
import useRefreshToken from '@/hooks/RefreshToken';
import useAuth from '@/hooks/Auth';
import constants from '@/constants';

const { DEV, BACKEND_HOST } = constants;

const BACKEND_URL = `${DEV ? 'ws' : 'wss'}://${BACKEND_HOST}`;

interface SocketWithToken extends Socket {
  auth: {
    [key: string]: any;
  } & { token?: string };
}

interface UseSocketProps {
  query?: { [key: string]: any } | undefined;
  nameSpace?: string;
  event: string;
  shouldConnect?: boolean;
}

let retryTokenRefresh = false;

const useSocket = <EmitMessagePayload = any, ReceivedMessagePayload = any>({
  query,
  nameSpace = '',
  event,
  shouldConnect,
}: UseSocketProps) => {
  const [message, setMessage] = useState<ReceivedMessagePayload>();

  const resfreshToken = useRefreshToken();

  const { user } = useAuth();

  const socketUrl = useMemo(() => `${BACKEND_URL}/${nameSpace}`, [nameSpace]);

  const [socket, setSocket] = useState<SocketWithToken | null>(() => {
    if (!shouldConnect) return null;
    const token = user?.accessToken;
    return io(socketUrl, {
      auth: { token },
      query: query,
      secure: true,
      withCredentials: true,
    });
  });

  useEffect(() => {
    const token = user?.accessToken;

    setSocket((prevSocket) => {
      if (!shouldConnect) return null;

      if (prevSocket) {
        prevSocket.disconnect();
      }

      return io(socketUrl, {
        auth: { token },
        query: query,
        secure: true,
        withCredentials: true,
      });
    });
  }, [user?.accessToken, query, socketUrl, shouldConnect]);

  const disconnect = useCallback(() => socket?.disconnect(), [socket]);

  const emit = useCallback(
    (e: string, ...args: EmitMessagePayload[]) => {
      socket?.emit(e, args);
    },
    [socket]
  );

  socket?.on('connect_error', async (e: any) => {
    console.error('connect_error', e);

    const invalidCredentials = !!e?.data?.credentials;

    let token: string | null = null;

    if (invalidCredentials && retryTokenRefresh) {
      retryTokenRefresh = false;
      token = await resfreshToken();
    }

    if (!token) {
      disconnect();
    } else {
      retryTokenRefresh = true;
    }
  });

  useEffect(() => {
    if (!socket) return;

    if (!socket.connected && shouldConnect) {
      socket?.connect();
    }

    socket.on(event, (response) => {
      setMessage(response);
    });

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [socket, event, shouldConnect]);

  return {
    emit,
    message,
    disconnect,
  };
};

export default useSocket;
