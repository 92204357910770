import { FC, MouseEvent, useState } from 'react';
import Menu from '@mui/material/Menu';
import { Wrapper, Button, StyledMenuList } from './DropdownMenu.styles';
import { IDropdownMenu } from './DropdownMenu.types';
import { EllipsisIcon } from '@/icons';

const PAPER_PROPS = {
  sx: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
};

const MENU_LIST_PROPS = {
  'aria-labelledby': 'dropdown-button',
  sx: {
    backgroundColor: '#F8F8F8',
  },
};

const MENU_SX = { zIndex: 1299 };

const DropdownMenu: FC<IDropdownMenu> = ({
  menu,
  buttonLabel,
  buttonIcon,
  className,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper className={className}>
      <Button
        id="dropdown-button"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        type="button"
      >
        {buttonLabel || buttonIcon || <EllipsisIcon />}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={MENU_SX}
        PaperProps={PAPER_PROPS}
        MenuListProps={MENU_LIST_PROPS}
      >
        <StyledMenuList menu={menu} />
      </Menu>
    </Wrapper>
  );
};

export default DropdownMenu;
