import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IBusinessUnit, IBusinessUnitContext } from './BusinessUnit.types';
import constants from '@/constants/';
import useAxiosPrivate from '@/hooks/AxiosPrivate';
import useAuth from '@/hooks/Auth';
import userSession from '@/utils/UserSession';

const { CURRENCY_CODES } = constants;

export const BusinessUnitContext = createContext<IBusinessUnitContext>(
  {} as IBusinessUnitContext
);

export const BusinessUnitProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const initialValue = userSession.getBusinessUnit();

  const [businessUnits, setBusinessUnits] = useState<IBusinessUnit[]>([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<string>(initialValue);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>('€');
  const [selectedDialCode, setSelectedDialCode] = useState<string>('+351');

  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth();

  useEffect(() => {
    if (selectedBusinessUnit) {
      userSession.setBusinessUnit(selectedBusinessUnit);

      const bu = businessUnits?.find((x) => x.code === selectedBusinessUnit);
      const currency = bu?.currencyCode || '';
      const dialCode = bu?.dialCode || '+351';

      setSelectedCurrencyCode(CURRENCY_CODES[currency] || '€');
      setSelectedDialCode(dialCode);
    }
  }, [businessUnits, selectedBusinessUnit]);

  const getBusinessUnits = useCallback(async () => {
    try {
      const { data } = await axiosPrivate.get<{
        payload: { businessUnits: { code?: string; currencyCode?: string }[] };
      }>('/api/users/me/business-units');

      const units = data?.payload?.businessUnits || [
        { code: user?.data?.businessUnitId, currencyCode: 'EUR' },
      ];

      setBusinessUnits(units);

      if (units.length === 1 || !units.find((x) => x.code === initialValue)) {
        setSelectedBusinessUnit(units[0].code!);
      }
    } catch (e) {
      setBusinessUnits([]);
    }
  }, [axiosPrivate, initialValue, user?.data?.businessUnitId]);

  useEffect(() => {
    getBusinessUnits();
  }, [getBusinessUnits]);

  return (
    <BusinessUnitContext.Provider
      value={{
        businessUnits,
        selectedBusinessUnit,
        setSelectedBusinessUnit,
        selectedCurrencyCode,
        selectedDialCode,
      }}
    >
      {children}
    </BusinessUnitContext.Provider>
  );
};
