import { FC } from 'react';
import { Wrapper } from './Breadcrumbs.styles';
import { IBreadcrumbs, IBreadcrumb } from './Breadcrumbs.types';
import CONSTANTS from '@/constants';
import { matchRoutes, Link } from 'react-router-dom';
import { ArrowLeftShortIcon } from '@/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
const { PAGES } = CONSTANTS;

const generateBreadcrumbs = (
  t: TFunction,
  currentRoute: { path: string; title: string }
) => {
  const arrayOfPaths = currentRoute.path.split('?')[0].split('/').slice(1);

  const crumbs = arrayOfPaths
    .map((_, i) => {
      const href = '/' + arrayOfPaths.slice(0, i + 1).join('/');

      const matchedRoutes =
        (matchRoutes(PAGES, href) as
          | { route: { path: string; title: string } }[]
          | null) || [];

      return {
        breadcrumb: t(matchedRoutes[0].route?.title || ''),
        href,
      };
    })
    .slice(0, -1);

  crumbs.push({
    breadcrumb: t(currentRoute.title),
    href: '#',
  });

  return crumbs;
};

const Breadcrumbs: FC<IBreadcrumbs> = ({ route }) => {
  const { t } = useTranslation();

  const crumbs = generateBreadcrumbs(t, route);
  return route?.path !== '/dashboard' ? (
    <Wrapper>
      <ul>
        <li>
          <Link to={-1 as any}>
            <ArrowLeftShortIcon />
          </Link>
        </li>
        {crumbs.map((breadcrumb: IBreadcrumb) => {
          return (
            <li key={breadcrumb.href}>
              {breadcrumb.href === '#' ? (
                breadcrumb.breadcrumb
              ) : (
                <Link to={breadcrumb.href}>{breadcrumb.breadcrumb}</Link>
              )}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  ) : (
    <></>
  );
};

export default Breadcrumbs;
