import {
  useState,
  createContext,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { IExportContext } from './Export.types';
import useSocket from '@/hooks/Socket';
import useAxiosPrivate from '@/hooks/AxiosPrivate/';
import emitAlert from '@/utils/Toastify/Toastify';
import useAuth from '@/hooks/Auth';
import ExportFeedback from '@/components/ExportFeedback';

export const ExportContext = createContext<IExportContext>({
  canExport: true,
  handleExport: () => undefined,
  setCanExport: () => undefined,
  setCollection: () => undefined,
});

export const ExportProvider = ({ children }: { children: ReactNode }) => {
  const [canExport, setCanExport] = useState<boolean>(true);
  const [collection, setCollection] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receivedMessage, setReceivedMessage] = useState<{
    url: string;
  } | null>(null);

  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth();

  const socketQuery = useMemo(() => ({ collection }), [collection]);

  const { message } = useSocket<any, { url: string }>({
    query: socketQuery,
    nameSpace: 'exports',
    event: `${user?.data?.id}-export-${collection}`,
    shouldConnect: !!collection,
  });

  useEffect(() => {
    if (message) {
      setCanExport(true);
      setReceivedMessage(message);
      setLoading(false);
    }
  }, [message, setCanExport]);

  const handleExport = useCallback(
    async (collection: string, queryString: string) => {
      try {
        setCanExport(false);
        setOpenDialog(true);
        setCollection(collection);
        setLoading(true);
        await axiosPrivate.get(
          `/api/${collection}/export?${queryString || ''}`
        );
      } catch (e) {
        emitAlert({
          message: 'Erro ao processar exportação',
          duration: 3000,
          type: 'error',
        });

        setCanExport(true);
        setLoading(false);
        setOpenDialog(false);
      }
    },
    [setCanExport, axiosPrivate]
  );

  return (
    <ExportContext.Provider
      value={{
        handleExport,
        canExport,
        setCanExport,
        setCollection,
      }}
    >
      {children}
      <ExportFeedback
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        href={receivedMessage?.url || '#'}
        disabled={!canExport}
        loading={loading}
      />
    </ExportContext.Provider>
  );
};
