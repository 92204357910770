import { useCallback, useEffect } from 'react';
import useAuth from '@/hooks/Auth';
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '@/hooks/AxiosPrivate';
import userSession from '@/utils/UserSession';
import inMemoryJwt from '@/utils/InMemoryJwt';
import { IUser } from '@/types';
import { useTranslation } from 'react-i18next';

export const PrivateRoute = () => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();

  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const getUser = useCallback(async () => {
    try {
      const { data } = await axiosPrivate.get<{
        payload: { user: IUser };
      }>('/api/users/me');

      if (!data?.payload) {
        throw new Error(t('dashboard.expired-session'));
      }

      setUser((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          data: data.payload.user,
        };
      });
    } catch (err) {
      setUser(null);
      userSession.remove();
      inMemoryJwt.eraseToken();
      navigate('/', { state: { from: location }, replace: true });
    }
  }, [axiosPrivate, setUser, t, navigate, location]);

  useEffect(() => {
    if (!user?.data) {
      getUser();
    }
  }, [getUser, user]);

  return user ? (
    <Outlet />
  ) : user === undefined ? (
    <div />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
