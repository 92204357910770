import styled from 'styled-components';

const FormComponentStyled = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  text-align: left;

  & * {
    font-family: ${(props) => props.theme.fontFamilies.montserrat} !important;
  }
`;

const FormLabel = styled.label`
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const FootNote = styled.div<{
  error: boolean;
}>`
  margin: 0;
  padding: 0;
  height: 15px;
  p {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: ${(props) => (props.error ? 'red' : props.theme.palette.darkGrey)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export { FormLabel, FormComponentStyled, FootNote };
