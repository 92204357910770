import { FC } from 'react';
import { WrapperAvatar } from './Avatar.styles';
import { IAvatar } from './Avatar.types';
const Avatar: FC<IAvatar> = ({ size, avatarUrl, className }) => {
  return (
    <WrapperAvatar
      className={className}
      style={size ? { width: size, height: size } : {}}
    >
      <img
        src={avatarUrl ? avatarUrl : '/images/default-avatar.png'}
        alt="insparya Avatar"
      />
    </WrapperAvatar>
  );
};

export default Avatar;
