import {
  FormLabel,
  FormComponentStyled,
  FootNote,
} from './FormComponentWrapper.styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IFormComponentWrapper } from './FormComponentWrapper.types';
import Tooltip from '@/components/Tooltip';
import { FC } from 'react';
const FormComponentWrapper: FC<IFormComponentWrapper> = ({
  label,
  name,
  required,
  tooltip,
  footnote,
  error = false,
  children,
  noFootnote = false,
  className,
}) => {
  return (
    <FormComponentStyled className={className}>
      {label && name && (
        <FormLabel htmlFor={name}>{`${label}${required ? '*' : ''}`}</FormLabel>
      )}
      {tooltip && (
        <Tooltip title={tooltip}>
          {(handleTooltipOpen) => (
            <div>
              <HelpOutlineIcon
                color="primary"
                fontSize="small"
                onClick={handleTooltipOpen}
              />
            </div>
          )}
        </Tooltip>
      )}
      {children}
      {!noFootnote && (
        <FootNote error={error}>{footnote && <p>{footnote}</p>}</FootNote>
      )}
    </FormComponentStyled>
  );
};

export default FormComponentWrapper;
