import useAuth from '@/hooks/Auth';
import { useCallback, useEffect } from 'react';
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import axios from '@/utils/Axios';
import userSession from '@/utils/UserSession';

export const PublicRoute = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const checkInitialized = useCallback(async () => {
    try {
      const { data } = await axios.get('/api/initialized');

      if (!data || !data?.payload) return false;

      if (data?.payload?.status === false && location.pathname !== '/signup') {
        navigate('/signup', {
          state: { from: location },
          replace: true,
        });
      } else if (data?.payload?.status && location.pathname === '/signup') {
        navigate('/', {
          state: { from: location },
          replace: true,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [location, navigate]);

  useEffect(() => {
    const hasValidSession = userSession.isValid();
    if (hasValidSession) {
      return navigate('/dashboard', {
        state: { from: location },
        replace: true,
      });
    } else {
      userSession.remove();
      checkInitialized();
    }
  }, [checkInitialized, navigate, location]);

  return !user ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  );
};

export default PublicRoute;
