import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@/styles/theme';
import { GlobalStyle } from '@/styles/global';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from '@/contexts/Auth';
import './i18n/config';
import App from './App';

import 'react-toastify/dist/ReactToastify.min.css';
import { LanguageProvider } from './contexts/Language';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const muiTheme = createTheme({
  palette: {
    secondary: {
      main: defaultTheme.palette.common.mainDark,
    },
    primary: {
      main: defaultTheme.palette.common.main,
    },
    success: {
      main: defaultTheme.palette.green,
    },
    error: {
      main: defaultTheme.palette.red,
    },
    warning: {
      main: defaultTheme.palette.yellow,
    },
    background: {
      default: defaultTheme.palette.common.pageBackground,
    },
  },
});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <ThemeProvider theme={defaultTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <GlobalStyle />
              <ToastContainer />
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </MuiThemeProvider>
          </ThemeProvider>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  </React.StrictMode>
);
