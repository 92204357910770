import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Avatar from '@/components/Avatar';
import { animation } from '@/styles/mixins';
import Select from '@/components/Select';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h1<{ $isHome: boolean }>`
  font-size: 21px;
  color: ${(props) => props.theme.palette.common.primaryText};
  font-family: ${(props) => props.theme.fontFamilies.montserrat};
  font-weight: ${(props) => (props.$isHome ? 300 : 600)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
  /* max-width: 220px; */
  width: 100%;

  strong {
    font-weight: 600;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 0;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    max-width: initial;
    width: initial;
    font-size: 24px;
  }
`;

export const Column = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -40px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    align-items: center;
    margin-top: 0;
  }
`;

export const StyledAvatar = styled(Avatar)`
  ${animation({ time: '.3s' })}
`;

export const AvatarWithLabelLink = styled(Link)<{ $isHome: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 0;
  }

  &:hover {
    ${StyledAvatar} {
      border: 2px solid ${(props) => props.theme.palette.common.main};
    }
  }
`;

export const UsernameLabel = styled.h4`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: ${(props) => props.theme.palette.common.primaryText};
    font-family: ${(props) => props.theme.fontFamilies.montserrat};
    font-weight: 500;
    margin-right: 15px;
  }
`;

export const StyledSelect = styled(Select)<{ $width: string }>`
  max-width: initial;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 15px;
    width: ${(props) => props.$width}!important;
  }
`;
