import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Login = lazy(() => import('@/pages/Login'));
const Signup = lazy(() => import('@/pages/Signup'));
const ExternalSignup = lazy(() => import('@/pages/ExternalSignup'));
const RecoverPassword = lazy(() => import('@/pages/RecoverPassword'));

const PUBLIC_ROUTES = (
  <>
    <Route path="/" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/external-signup/:token" element={<ExternalSignup />} />
    <Route path="/recover-password" element={<RecoverPassword />} />
  </>
);
export default PUBLIC_ROUTES;
