import BaseButton from '@/components/BaseButton';
import { IDeleteButton } from './DeleteButton.types';
import { FC } from 'react';
const DeleteButton: FC<IDeleteButton> = ({ children, ...props }) => {
  return (
    <BaseButton error {...props}>
      {children}
    </BaseButton>
  );
};

export default DeleteButton;
