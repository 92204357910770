import { lazy } from 'react';
import { Route, Outlet } from 'react-router-dom';

const Commissions = lazy(() => import('@/pages/Commissions'));
const CommissionsCreate = lazy(() => import('@/pages/CommissionsCreate'));
const CommissionsEdit = lazy(() => import('@/pages/CommissionsEdit'));
const CommissionStructures = lazy(() => import('@/pages/CommissionStructures'));
const CommissionStructuresCreate = lazy(
  () => import('@/pages/CommissionStructuresCreate')
);
const CommissionStructuresEdit = lazy(
  () => import('@/pages/CommissionStructuresEdit')
);
const CommissionTree = lazy(() => import('@/pages/CommissionTree'));

const COMMISSION_ROUTES = (
  <Route
    path="/dashboard/comissoes"
    element={<Outlet />}
    key="/dashboard/comissoes"
  >
    <Route
      path="/dashboard/comissoes/regras-comissao"
      element={<CommissionStructures />}
    />
    <Route
      path="/dashboard/comissoes/regras-comissao/create"
      element={<CommissionStructuresCreate />}
    />
    <Route
      path="/dashboard/comissoes/regras-comissao/:id"
      element={<CommissionStructuresEdit />}
    />
    <Route
      path="/dashboard/comissoes/arvore-comissao"
      element={<CommissionTree />}
    />
    <Route path="/dashboard/comissoes" element={<Commissions />} />
    <Route path="/dashboard/comissoes/create" element={<CommissionsCreate />} />
    <Route path="/dashboard/comissoes/:id" element={<CommissionsEdit />} />
  </Route>
);
export default COMMISSION_ROUTES;
