import moment from 'moment';
import constants from '@/constants';

const {
  DEFAULT_BUSINESS_UNIT,
  DEFAULT_LNG,
  REFRESH_TOKEN_EXPIRATION_TIME,
  DEFAULT_LOGIN_REDIRECT_PATH,
} = constants;

const userSession = {
  init: () => {
    const now = new Date();
    window.localStorage.setItem('last_login', now.toISOString());
  },
  isValid: () => {
    const lastLogin = window.localStorage.getItem('last_login');
    if (!lastLogin) return false;
    const isDateValid = moment(lastLogin).isValid;
    if (!isDateValid) return false;
    let lastLoginHoursOld = moment().diff(moment(lastLogin), 'hours');
    //24 hour is the expiration of a refresh token
    return lastLoginHoursOld < REFRESH_TOKEN_EXPIRATION_TIME;
  },
  setBusinessUnit: (buId: string) => {
    window.localStorage.setItem('bu', buId);
  },
  getBusinessUnit: () => {
    return window.localStorage.getItem('bu') || DEFAULT_BUSINESS_UNIT;
  },
  setLng: (lng: string) => {
    window.localStorage.setItem('lng', lng);
  },
  getLng: () => {
    return window.localStorage.getItem('lng') || DEFAULT_LNG;
  },
  setLoginRedirectPath: (bu: string, path: string) => {
    window.localStorage.setItem(`${bu}:login_redirect`, path);
  },
  getLoginRedirectPath: (
    bu: string,
    options?: { useDefaultIfEmpty: boolean }
  ) => {
    const { useDefaultIfEmpty = true } = options || {};
    const redirectPath = window.localStorage.getItem(`${bu}:login_redirect`);
    return (
      redirectPath || (useDefaultIfEmpty ? DEFAULT_LOGIN_REDIRECT_PATH : null)
    );
  },
  remove: () => {
    window.localStorage.removeItem('last_login');
  },
};

export default userSession;
