import axios, { AxiosError } from 'axios';
import constats from '@/constants';
import emitAlert from '../Toastify/Toastify';

const { BACKEND_HOST, DEV, DEFAULT_ERROR_MESSAGE } = constats;

const BACKEND_URL = `${DEV ? 'http' : 'https'}://${BACKEND_HOST}`;

export default axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const isAxiosError = (
  e: any
): e is AxiosError<{ error: { code: string; message: string } }> =>
  !!e?.isAxiosError;

export const handleAxiosSubmissionError = (err: any, msg?: string) => {
  let errorMessage = msg || DEFAULT_ERROR_MESSAGE;

  if (isAxiosError(err)) {
    errorMessage = err?.response?.data?.error?.message || DEFAULT_ERROR_MESSAGE;
  }

  emitAlert({
    message: errorMessage,
    type: 'error',
    duration: 2500,
  });
};
