import { FC, useState, createContext, ReactNode, useEffect } from 'react';
import { ILanguageContext } from './Language.types';
import userSession from '@/utils/UserSession';
import { useTranslation } from 'react-i18next';

export const LanguageContext = createContext<ILanguageContext>(
  {} as ILanguageContext
);

export const LanguageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const initialValue = userSession.getLng();

  const [selectedLng, setSelectedLng] = useState<string>(initialValue);

  useEffect(() => {
    if (selectedLng) {
      i18n.changeLanguage(selectedLng);
      userSession.setLng(selectedLng);
    }
  }, [i18n, selectedLng]);

  return (
    <LanguageContext.Provider
      value={{
        selectedLng,
        setSelectedLng,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
