import styled from 'styled-components';
import Snackbar from '@mui/material/Snackbar';

export const StyledSnackbar = styled(Snackbar)`
  & .MuiSnackbarContent-message {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    width: 32px;
    stroke: ${(props) => props.theme.palette.common.main};
  }
`;
