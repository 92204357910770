import styled from 'styled-components';
import MaterialTooltip from '@mui/material/Tooltip';

const Tooltip = styled(MaterialTooltip)`
  width: fit-content;
  cursor: help;
  position: absolute;
  top: 5px;
  right: 0px;

  svg {
    fill: ${(props) => props.theme.palette.common.main};
  }
`;

export { Tooltip };
