import { matchRoutes, Outlet, useLocation } from 'react-router-dom';
import { useState, useEffect, memo, FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Header from '@/components/Header';
import Drawer from '@/components/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '@/hooks/Auth';
import { IPrivateLayout, AppBarProps } from './PrivateLayout.types';
import { StyledMenuIcon, Wrapper, StyledToolbar } from './PrivateLayout.styles';
import CONSTANTS from '@/constants';
import SelectRedirectPathDialog from '@/components/SelectRedirectPathDialog';

const { PAGES } = CONSTANTS;

const DRAWER_WIDTH = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  height: '100%',
  minHeight: '100vh',
  padding: '15px',
  width: '100%',
  overflowX: 'hidden',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isHome',
})<AppBarProps>(({ theme, open, isHome }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    isHome && {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: `${DRAWER_WIDTH}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const isDashboardPath = (pathname: string) =>
  pathname === '/dashboard' || pathname === '/dashboard/';

const PrivateLayout: FC<IPrivateLayout> = () => {
  const location = useLocation();
  const matchedRoutes = useMemo(() => {
    return (
      (matchRoutes(PAGES, location) as
        | { route: { path: string; title: string } }[]
        | null) || []
    );
  }, [location]);
  const isDesktop = useMediaQuery('(min-width:768px)');
  const { user } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  const [isHome, setIsHome] = useState<boolean>(
    isDashboardPath(location.pathname) && isDesktop
  );

  useEffect(() => {
    const homeDesktopState = isDashboardPath(location.pathname) && isDesktop;
    setIsHome(homeDesktopState);
    setOpen(homeDesktopState);
  }, [location.pathname, isDesktop]);

  return (
    <Wrapper>
      <AppBar
        color="transparent"
        style={{ boxShadow: 'none' }}
        position="absolute"
        open={open}
        isHome={isHome}
      >
        <StyledToolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              mr: 2,
              ...(open && isHome && { display: 'none' }),
            }}
          >
            <StyledMenuIcon />
          </IconButton>
          <Header
            username={
              user?.data ? `${user.data.name} ${user.data.surname}` : ''
            }
            isHome={isDashboardPath(location.pathname)}
            route={matchedRoutes[0]?.route}
            avatarUrl={user?.data?.avatar?.url || ''}
          />
        </StyledToolbar>
      </AppBar>
      <Drawer isHome={isHome} open={open} setOpen={setOpen} />
      <Main open={open}>
        <Outlet />
      </Main>
      <SelectRedirectPathDialog />
    </Wrapper>
  );
};

export default memo(PrivateLayout);
