import styled from 'styled-components';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export const StyledDialogActions = styled(DialogActions)<{
  $actionsFullWidth?: boolean;
}>`
  gap: 8px;
  ${(props) => props.$actionsFullWidth && 'padding: 0 !important;'}
`;

export const StyledDialogCloseOnTop = styled(DialogActions)`
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 30px;
  svg {
    fill: ${(props) => props.theme.palette.common.secundaryText};
  }
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.common.pageBackground};
    transition: 0.2s;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0 0 10px 0;
    text-align: left;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    min-width: 300px;
  }
`;

export const Title = styled.h3<{ $centralize?: boolean }>`
  ${(props) => props.$centralize && 'text-align: center;'}
`;
