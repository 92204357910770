import { FC, useState, createContext, ReactNode } from 'react';
import { IAuthContext, IAuthUser } from './Auth.types';

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IAuthUser | null | undefined>(undefined);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
