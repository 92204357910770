import { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import Menu from '@/components/Menu';
import { IDrawer } from './Drawer.types';
import { Wrapper } from './Drawer.styles';

const DRAWER_WIDTH = 260;

const CustomDrawer: FC<IDrawer> = ({ isHome, open, setOpen, className }) => {
  return (
    <Wrapper className={className}>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        variant={isHome ? 'persistent' : 'temporary'}
        anchor="left"
        PaperProps={{
          style: {
            backgroundColor: '#363740',
            borderRight: 'none',
            overflowX: 'hidden',
            overflowY: 'auto',
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Menu />
      </Drawer>
    </Wrapper>
  );
};

export default CustomDrawer;
