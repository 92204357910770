import { useState, useMemo, useCallback, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { IPageListItem } from '@/types';
import usePermissionGate from '@/hooks/PermissionGate/';
import { useBusinessUnit } from '@/hooks/BusinessUnit';
import Select from '@/components/Select';
import CONSTANTS from '@/constants';
import { ISelectRedirectPath } from './SelectRedirectPath.types';
import { ISelectOption } from '../Select/Select.types';

const { PAGES } = CONSTANTS;

const getSelectedPathValue = (options: ISelectOption[], path: string) => {
  const selectedPath = options.find((p) => p.value === path);
  return selectedPath ? selectedPath.value : undefined;
};

const SelectRedirectPath = (props: ISelectRedirectPath) => {
  const { defaultValue, onChange } = props;

  const { t } = useTranslation();
  const { selectedBusinessUnit } = useBusinessUnit();

  const [memoizedOnChange] = useState(() => onChange);

  const filteredPages = usePermissionGate<IPageListItem>(PAGES);

  const pageOptions = useMemo(
    () =>
      filteredPages
        .filter((p) => !!p.canBeRedirectPath)
        .map((p) => ({ value: p.path, text: t(p.title) })),
    [filteredPages, t]
  );

  const [pathAfterLogin, setPathAfterLogin] = useState(() => {
    return defaultValue
      ? getSelectedPathValue(pageOptions, defaultValue)
      : undefined;
  });

  useEffect(() => {
    setPathAfterLogin(() => {
      return defaultValue
        ? getSelectedPathValue(pageOptions, defaultValue)
        : undefined;
    });
  }, [defaultValue, pageOptions]);

  const handleChangePath = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const {
        target: { value },
      } = e;
      if (typeof value === 'string') {
        setPathAfterLogin(value);
        memoizedOnChange && memoizedOnChange(value);
      }
    },
    [memoizedOnChange]
  );

  return (
    <Select
      label={`${t(
        'components.select-redirect-path.label'
      )} (${selectedBusinessUnit})`}
      options={pageOptions}
      name="page.options"
      value={pathAfterLogin || undefined}
      onChange={handleChangePath}
    />
  );
};

export default memo(SelectRedirectPath);
