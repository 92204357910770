import { lazy } from 'react';
import { Route } from 'react-router-dom';

const AssignmentVoucher = lazy(() => import('@/pages/AssignmentVoucher'));

const CHAT_ROUTE = (
  <Route
    key="/dashboard/atribuir-parceria"
    path="/dashboard/atribuir-parceria"
    element={<AssignmentVoucher />}
  />
);

const ASSIGMENT_PARTNERSHIP_ROUTES = [CHAT_ROUTE];

export default ASSIGMENT_PARTNERSHIP_ROUTES;
