const DISTRICTS = [
  {
    value: 'Aveiro',
    text: 'Aveiro',
  },
  {
    value: 'Açores',
    text: 'Açores',
  },
  {
    value: 'Beja',
    text: 'Beja',
  },
  {
    value: 'Braga',
    text: 'Braga',
  },
  {
    value: 'Bragança',
    text: 'Bragança',
  },
  {
    value: 'Castelo Branco',
    text: 'Castelo Branco',
  },
  {
    value: 'Coimbra',
    text: 'Coimbra',
  },
  {
    value: 'Évora',
    text: 'Évora',
  },
  {
    value: 'Faro',
    text: 'Faro',
  },
  {
    value: 'Guarda',
    text: 'Guarda',
  },
  {
    value: 'Leiria',
    text: 'Leiria',
  },
  {
    value: 'Lisboa',
    text: 'Lisboa',
  },
  {
    value: 'Madeira',
    text: 'Madeira',
  },
  {
    value: 'Portalegre',
    text: 'Portalegre',
  },
  {
    value: 'Porto',
    text: 'Porto',
  },
  {
    value: 'Santarém',
    text: 'Santarém',
  },
  {
    value: 'Setúbal',
    text: 'Setúbal',
  },
  {
    value: 'Viana do Castelo',
    text: 'Viana do Castelo',
  },
  {
    value: 'Vila Real',
    text: 'Vila Real',
  },
  {
    value: 'Viseu',
    text: 'Viseu',
  },
];

export default DISTRICTS;
