import {
  AppointmentStatus,
  AppointmentTypes,
  UserTypes,
  CommissionTypes,
  ParameterKeys,
  ChatStatusTypes,
  TransplantTypes,
  BusinessUnitStatusTypes,
  VoucherPackAssociationTypes,
  AttributionRuleTypes,
} from '@/types';

type EnumOptions<T = string> = {
  value: T;
  text: string;
}[];

export const APPOINTMENT_TYPES: EnumOptions<AppointmentTypes> = [
  {
    value: 'ONLINE',
    text: 'enums.appointment-type.online',
  },
  {
    value: 'ONSITE',
    text: 'enums.appointment-type.onsite',
  },
  {
    value: 'UNKNOWN',
    text: 'enums.appointment-type.unknown',
  },
];

export const APPOINTMENT_STATUS: EnumOptions<AppointmentStatus> = [
  {
    value: 'WON',
    text: 'enums.appointment-status.won',
  },
  {
    value: 'LOST',
    text: 'enums.appointment-status.lost',
  },
  {
    value: 'PENDING',
    text: 'enums.appointment-status.pending',
  },
];

export const USER_TYPES: EnumOptions<UserTypes> = [
  {
    value: 'SUPER_ADMIN',
    text: 'enums.user-types.super-administrator',
  },
  {
    value: 'ADMIN',
    text: 'enums.user-types.administrator',
  },
  {
    value: 'PARTNERS_MANAGER',
    text: 'enums.user-types.partner-manager',
  },
  {
    value: 'PARTNER',
    text: 'enums.user-types.partner',
  },
];

export const COMMISSION_TYPES: EnumOptions<CommissionTypes> = [
  {
    value: 'CASH',
    text: 'Dinheiro',
  },
  {
    value: 'POINTS',
    text: 'Pontos',
  },
];

export const PARAMETER_KEYS: EnumOptions<ParameterKeys> = [
  {
    value: 'MANAGEMENT_COMMISSION',
    text: 'enums.parameters.management-commission',
  },
  {
    value: 'RECRUITMENT_COMMISSION',
    text: 'enums.parameters.recruitment-commission',
  },
  {
    value: 'CASH_TO_POINTS_MULTIPLE',
    text: 'enums.parameters.multiple-points',
  },
  {
    value: 'EXTERNAL_SIGNUP_TOKEN',
    text: 'enums.parameters.external-signup-token',
  },
];

export const FIELDS_TO_TRACK_CHANGES: EnumOptions<
  'email' | 'fullCellphone' | 'vatin' | 'iban'
> = [
  {
    value: 'email',
    text: 'Email',
  },
  {
    value: 'fullCellphone',
    text: 'Telemóvel',
  },
  {
    value: 'vatin',
    text: 'NIF',
  },
  {
    value: 'iban',
    text: 'IBAN',
  },
];

export const CHAT_STATUS: EnumOptions<ChatStatusTypes> = [
  {
    value: 'PENDING',
    text: 'enums.chat.pending',
  },
  {
    value: 'SOLVED',
    text: 'enums.chat.solved',
  },
];

export const TRANSPLANT_TYPES: EnumOptions<TransplantTypes> = [
  {
    value: 'BEARD',
    text: 'enums.transplant-type.beard',
  },
  {
    value: 'EYEBROWS',
    text: 'enums.transplant-type.eyebrows',
  },
  {
    value: 'HAIR',
    text: 'enums.transplant-type.hair',
  },
  {
    value: 'UNKNOWN',
    text: 'enums.transplant-type.unknown',
  },
];

export const BUSINESS_UNIT_STATUS: EnumOptions<BusinessUnitStatusTypes> = [
  {
    value: 'ACTIVE',
    text: 'common.active',
  },
  {
    value: 'INACTIVE',
    text: 'common.inactive',
  },
];

export const VOUCHER_PACK_ASSOCIATION_TYPES: EnumOptions<VoucherPackAssociationTypes> =
  [
    {
      value: 'CATEGORIES',
      text: 'voucher-pack.categories',
    },
    {
      value: 'PARTNERS',
      text: 'voucher-pack.partners',
    },
  ];

export const ATTRIBUTION_RULE_SOURCE: EnumOptions<AttributionRuleTypes> = [
  {
    value: 'MARKETING',
    text: 'attribution-rule.marketing',
  },
  {
    value: 'PARTNERSHIP',
    text: 'attribution-rule.partnership',
  },
  {
    value: 'VOUCHER',
    text: 'attribution-rule.voucher',
  },
];
