import { IPageListItem } from '@/types';

const PAGES: IPageListItem[] = [
  {
    title: 'dashboard.title',
    path: '/dashboard',
    canBeRedirectPath: true,
  },
  {
    title: 'profile.title',
    path: '/dashboard/profile',
    canBeRedirectPath: true,
  },
  {
    title: 'partner.title',
    path: '/dashboard/parceiros',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'partner.new-title',
    path: '/dashboard/parceiros/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners'],
      allowedAction: 'create',
    },
  },
  {
    title: 'partner.edit-title',
    path: '/dashboard/parceiros/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'client.title',
    path: '/dashboard/parceiros/clientes',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners.clients'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'client.new-title',
    path: '/dashboard/parceiros/clientes/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners.clients'],
      allowedAction: 'create',
    },
  },
  {
    title: 'client.edit-title',
    path: '/dashboard/parceiros/clientes/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'pending.title',
    path: '/dashboard/parceiros/pendentes',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners.pendings'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'pending.edit-title',
    path: '/dashboard/parceiros/pendentes/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'category.title',
    path: '/dashboard/parceiros/categorias',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners.categories'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'category.new-title',
    path: '/dashboard/parceiros/categorias/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners.categories'],
      allowedAction: 'create',
    },
  },
  {
    title: 'category.edit-title',
    path: '/dashboard/parceiros/categorias/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'invite.title',
    path: '/dashboard/parceiros/convites',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['partners.invitations'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'invite.edit-title',
    path: '/dashboard/parceiros/convites/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'sale.title',
    path: '/dashboard/vendas',
    canBeRedirectPath: true,
    requiredPermission: {
      operator: 'OR',
      allowedModules: ['sales', 'clinicAppointments', 'itemsSold'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'sale.edit-title',
    path: '/dashboard/vendas/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'lead.title',
    path: '/dashboard/leads',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['leads'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'lead.edit-title',
    path: '/dashboard/leads/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'notification.title',
    path: '/dashboard/notificacoes',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['notifications'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'notification.new-title',
    path: '/dashboard/notificacoes/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['notifications'],
      allowedAction: 'create',
    },
  },
  {
    title: 'notification.edit-title',
    path: '/dashboard/notificacoes/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'contract.title',
    path: '/dashboard/contrato',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['contracts'],
      allowedAction: 'readOne',
    },
  },
  {
    title: 'service.title',
    path: '/dashboard/servicos',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['services'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'service.edit-title',
    path: '/dashboard/servicos/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'service.new-title',
    path: '/dashboard/servicos/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['services'],
      allowedAction: 'create',
    },
  },
  {
    title: 'gallery.title',
    path: '/dashboard/galeria',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['files'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'commission.title',
    path: '/dashboard/comissoes',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['commissions'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'commission.new-title',
    path: '/dashboard/comissoes/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['commissions'],
      allowedAction: 'create',
    },
  },
  {
    title: 'commission.edit-title',
    path: '/dashboard/comissoes/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'commission.rule.title',
    path: '/dashboard/comissoes/regras-comissao',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['commissions.commissionStructures'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'commission.tree-title',
    path: '/dashboard/comissoes/arvore-comissao',
    canBeRedirectPath: false,
  },
  {
    title: 'commission.rule.new-title',
    path: '/dashboard/comissoes/regras-comissao/create',
    canBeRedirectPath: false,
  },
  {
    title: 'commission.rule.edit-title',
    path: '/dashboard/comissoes/regras-comissao/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'partner-management.title',
    path: '/dashboard/gestao-parceiros',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['activityDashboards'],
      allowedAction: 'readOne',
    },
  },
  {
    title: 'partner-management.manage-title',
    path: '/dashboard/gestao-parceiros/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'campaign.title',
    path: '/dashboard/campanhas',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['campaigns'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'campaign.new-title',
    path: '/dashboard/campanhas/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['campaigns'],
      allowedAction: 'create',
    },
  },
  {
    title: 'campaign.edit-title',
    path: '/dashboard/campanhas/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'chat.title',
    path: '/dashboard/chat',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['chats'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'parameters.title',
    path: '/dashboard/parametros',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['parameters'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'logs.title',
    path: '/dashboard/logs',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['fieldLevelChangesLogs'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'voucher-logs.title',
    path: '/dashboard/voucher-logs',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['voucherLogs'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'business-unit.title',
    path: '/dashboard/unidades',
    canBeRedirectPath: false,
    requiredPermission: {
      allowedModules: ['businessUnits'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'business-unit.new-title',
    path: '/dashboard/unidades/create',
    canBeRedirectPath: false,
    requiredPermission: {
      allowedModules: ['businessUnits'],
      allowedAction: 'create',
    },
  },
  {
    title: 'business-unit.edit-title',
    path: '/dashboard/unidades/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'users.title',
    path: '/dashboard/gestao-utilizadores',
    canBeRedirectPath: false,
  },
  {
    title: 'users.new-title',
    path: '/dashboard/gestao-utilizadores/create',
    canBeRedirectPath: false,
  },
  {
    title: 'users.edit-title',
    path: '/dashboard/gestao-utilizadores/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'policy.title',
    path: '/dashboard/politicas',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['policies'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'policy.new-title',
    path: '/dashboard/politicas/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['policies'],
      allowedAction: 'create',
    },
  },
  {
    title: 'policy.edit-title',
    path: '/dashboard/politicas/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'news.title',
    path: '/dashboard/noticias',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['news'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'news.new-title',
    path: '/dashboard/noticias/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['news'],
      allowedAction: 'create',
    },
  },
  {
    title: 'news.edit-title',
    path: '/dashboard/noticias/:id',
    canBeRedirectPath: false,
  },
  {
    title: 'recommendation.title',
    path: '/dashboard/criar-recomendacao',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['leads', 'partners'],
      allowedAction: 'create',
    },
  },
  {
    title: 'assignment-partnership.title',
    path: '/dashboard/atribuir-parceria',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['leads', 'partners'],
      allowedAction: 'create',
    },
  },
  {
    title: 'voucher-pack.title',
    path: '/dashboard/voucher-packs',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['voucherPacks'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'voucher-pack.new-title',
    path: '/dashboard/voucher-packs/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['voucherPacks'],
      allowedAction: 'create',
    },
  },
  {
    title: 'voucher-pack.detail-title',
    path: '/dashboard/voucher-packs/:id',
    canBeRedirectPath: false,
  },

  {
    title: 'attribution-rule.title',
    path: '/dashboard/regras-atribuicao',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['attributionRules'],
      allowedAction: 'readMany',
    },
  },
  {
    title: 'attribution-rule.new-title',
    path: '/dashboard/regras-atribuicao/create',
    canBeRedirectPath: true,
    requiredPermission: {
      allowedModules: ['attributionRules'],
      allowedAction: 'create',
    },
  },
  {
    title: 'attribution-rule.edit-title',
    path: '/dashboard/regras-atribuicao/:id',
    canBeRedirectPath: false,
  },
];

export default PAGES;
